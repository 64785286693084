<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Form</h1>
      <p class="subtitle">What should we call this patient form?</p>
    </div>
    <div class="modal-card-body">
      <div class="form-group">
        <label for="" class="form-label">Form Name</label>
        <input type="text" class="form-control" v-model="name">
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptCreateForm">Create</button>
    </div>
  </div>
</template>

<script>
import * as formsApi from '../../api/forms.js'

export default {
  name: "CreateFormModal",
  data() {
    return {
      name: ''
    }
  },
  methods: {
    async attemptCreateForm() {
      try {
        const form = await formsApi.createForm(this.name);
        this.$emit('onFormCreated', form);
      } catch (err) {
        console.error(err);
      }
    }
  },
  emits: ['openModal', 'closeModal', 'onFormCreated']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.user-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>