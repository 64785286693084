<template>
  <div class="wrapper">
    <FormEditor ref="editor" :formInfo="formInfo" @onDeleteSection="handleDeleteSection" @onFormUpdated="handleFormUpdated" />
    <transition name="modal-pop">
      <div class="center" v-if="needsSave">
      <button class="button primary" @click="handleSaveForm">Save Form</button>
    </div>
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <DeleteSectionModal v-if="modal.id == 'delete-section'" :section="modal.data.section" @confirmDeleteSection="confirmDeleteSection" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DeleteSectionModal from '../../modals/forms/DeleteSectionModal.vue';
import FormEditor from './FormEditor.vue'

export default {
  name: 'FormEditorPanel',
  props: {
    formInfo: Object
  },
  data() {
    return {
      isEditing: false,
      modal: {
        open: false,
        id: '',
        data: {}
      },
      needsSave: false
    }
  },
  methods: {
    toggleEditing() {
      this.isEditing = !this.isEditing;
    },
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleDeleteSection(section) {
      this.openModal('delete-section', { section });
    },
    confirmDeleteSection(section) {
      this.closeModal();
      const editorRef = this.$refs.editor;
      if (!editorRef) return;
      editorRef.confirmDeleteSection(section);
    },
    handleSaveForm() {
      const editorRef = this.$refs.editor;
      if (!editorRef) return;
      const sections = editorRef.getSections();
      const logoBase64 = editorRef.getLogo();
      this.$emit('onUpdateForm', sections, logoBase64);
      this.needsSave = false;
    },
    handleFormUpdated(sections) {
      if (JSON.stringify(sections) == JSON.stringify(this.formInfo.sections)) {
        return;
      }

      this.needsSave = true;
    }
  },
  components: { FormEditor, DeleteSectionModal },
  emits: ['onUpdateForm']
}
</script>

<style scoped>
.wrapper {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.center {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center .button {
  width: unset;
  box-shadow: 0px 0px 12px #00000022;
}
</style>