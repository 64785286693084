<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Delete Section</h1>
      <p class="subtitle">Are you sure you want to delete this section? This action cannot be undone.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="deleteSection">Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteSectionModal",
  props: {
    section: Object
  },
  methods: {
    deleteSection() {
      this.$emit('confirmDeleteSection', this.section);
    }
  },
  emits: ['confirmDeleteSection']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}

</style>