<template>
  <div class="panel" :data-collapsed="hasPageSelected()">
    <div class="sidebar-header">
      <h2>Account Settings</h2>
    </div>
    <div class="sidebar-body">
      <router-link class="menu-list-item" to="/settings/general" :data-active="$route.path.startsWith('/settings/general')">
        <img class="menu-icon" src="/icons/settings.png">
        <span>General</span>
      </router-link>
      <router-link class="menu-list-item" to="/settings/users" :data-active="$route.path.startsWith('/settings/users')">
        <img class="menu-icon" src="/icons/person.png">
        <span>Users</span>
      </router-link>
      <router-link class="menu-list-item" to="/settings/messaging" :data-active="$route.path.startsWith('/settings/messaging')">
        <img class="menu-icon" src="/icons/app-messaging.png">
        <span>Messaging</span>
      </router-link>
    </div>
    <div class="sidebar-header">
      <h2>User Settings</h2>
    </div>
    <div class="sidebar-body">
      <router-link class="menu-list-item" to="/settings/user-messaging" :data-active="$route.path.startsWith('/settings/user-messaging')">
        <img class="menu-icon" src="/icons/app-messaging.png">
        <span>Messaging</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {fetchAccountInfo} from '@/api'

export default {
  name: 'AccountPanel',
  data() {
    return {
      account: null,
      error: ""
    }
  },
  mounted() {
    this.attemptFetchAccount();
  },
  methods: {
    async attemptFetchAccount() {
      try {
        this.account = await fetchAccountInfo();
      } catch (err) {
        this.error = err.message;
        console.error(err);
      }
    },
    hasPageSelected() {
      const params = this.$route.params;
      if (params && params.page) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style scoped>
.panel {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform ease-in-out 0.25s, opacity ease-in-out 0.25s;
}

.sidebar-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 30px;
}

.sidebar-body {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.menu-list-item {
  padding: 15px;
  color: var(--color-text-dark);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  border-top: 1px solid var(--color-border-light);
}

.menu-list-item[data-active="true"] {
  background: var(--color-fill-med);
}

.menu-icon {
  width: 30px;
  height: 30px;
  display: block;
}

@media screen and (max-width: 1000px) {
  .panel {
    left: 10px;
    top: calc(80px + env(safe-area-inset-top));
    position: absolute;
    z-index: 100;
    height: calc(100% - 90px - env(safe-area-inset-top));
    width: calc(100% - 20px);
  }

  .panel[data-collapsed="true"] {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.back-icon {
  display: none;
  height: 24px;
}
</style>