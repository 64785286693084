<template>
  <div class="panel">
    <div class="panel-header">
      <router-link to="/settings">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <h2>Messaging</h2>
    </div>
    <div class="panel-body form" v-if="settings">
      <div class="form-group">
        <label for="" class="form-label">Patient chat notifications</label>
        <select type="text" class="form-control" v-model="settings.allIncomingVerifiedChats">
          <option :value="true">All chats</option>
          <option :value="false">None</option>
        </select>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Unverified chat notifications</label>
        <select type="text" class="form-control" v-model="settings.allIncomingUnverifiedChats">
          <option :value="true">All chats</option>
          <option :value="false">None</option>
        </select>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Group chat notifications</label>
        <select type="text" class="form-control" v-model="settings.allIncomingGroupChats">
          <option :value="true">All chats</option>
          <option :value="false">None</option>
        </select>
      </div>
    </div>
    <button class="button small" v-if="haveSettingsChanged()" @click="attemptSaveSettings">Save Changes</button>
  </div>
</template>

<script>
import { getUserMessagingSettings, setUserMessagingSettings } from '../../api';

export default {
  name: 'UserMessagingSettingsPanel',
  data() {
    return {
      previousSettings: null,
      settings: null,
      error: ''
    }
  },
  mounted() {
    this.attemptGetSettings();
  },
  methods: {
    async attemptGetSettings() {
      try {
        const settings = await getUserMessagingSettings();
        this.settings = settings;
        this.previousSettings = structuredClone(settings);
      } catch (err) {
        this.error = err.message;
      }
    },
    async attemptSaveSettings() {
      try {
        await setUserMessagingSettings(this.settings);
        this.previousSettings = structuredClone(this.settings);
      } catch (err) {
        this.error = err.message;
      }
    },
    haveSettingsChanged() {
      return JSON.stringify(this.settings) != JSON.stringify(this.previousSettings);
    }
  },
}
</script>

<style scoped>
.form {
  max-width: 600px;
}

.panel {
  flex: 1;
  align-items: flex-start;
}

.warning {
  margin-top: 10px;
  padding: 10px;
  background: rgb(255, 240, 191);
}

textarea {
  resize: none;
}

.panel-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .back-icon {
    display: block;
  }
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
}

.form-row {
  flex-wrap: wrap;
}

.form-row .time-control {
  width: 100px;
}
</style>