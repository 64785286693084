<template>
  <div id="full">
    <NavBar />
    <AccountListPanel ref="panel" @openModal="openModal" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <RemoveAccountModal v-if="modal.id == 'remove-account'" :account="modal.data.account" @onAccountRemoved="handleAccountRemoved" />
            <EditMessagingModal v-else-if="modal.id == 'edit-messaging'" :account="modal.data.account" @onMessagingToggled="handleMessagingToggled" />
            <EditMeetingsModal v-else-if="modal.id == 'edit-meetings'" :account="modal.data.account" @onMeetingsToggled="handleMeetingsToggled" />
            <AccountInfoModal v-else-if="modal.id == 'account-info'" :account="modal.data.account" @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import AccountListPanel from '../components/admin/AccountListPanel.vue';
import EditMessagingModal from '../modals/admin/EditMessagingModal.vue';
import EditMeetingsModal from '../modals/admin/EditMeetingsModal.vue';
import RemoveAccountModal from '../modals/admin/RemoveAccountModal.vue';
import AccountInfoModal from '../modals/admin/AccountInfoModal.vue';

export default {
  name: "AdminView",
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleAccountRemoved(accountId) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleAccountRemoved(accountId);
    },
    handleMessagingToggled(accountId, isEnabled) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleMessagingToggled(accountId, isEnabled);
    },
    handleMeetingsToggled(accountId, isEnabled) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleMeetingsToggled(accountId, isEnabled);
    }
  },
  components: { NavBar, AccountListPanel, RemoveAccountModal, EditMessagingModal, AccountInfoModal, EditMeetingsModal }
}
</script>

<style scoped>
</style>