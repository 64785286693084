<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Edit User</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptEditUser">
        <div class="form-group">
          <label class="form-label">User Name</label>
          <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="name">
        </div>
        <div class="form-group">
          <label class="form-label">Role In Practice</label>
          <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="role">
        </div>
        <input type="submit" value="" hidden>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptEditUser">Save Changes</button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { editUser } from '../../api';

export default {
  name: "EditUserModal",
  props: {
    user: Object
  },
  data() {
    return {
      name: this.user.name,
      role: this.user.practiceRole,
      pending: false,
      error: ''
    }
  },
  methods: {
    async attemptEditUser() {
      try {
        this.pending = true;
        const user = await editUser(this.user.id, this.name, this.role);
        this.$emit('onUserUpdated', user);
      } catch (err) {
        console.error('Failed to edit user', err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    }
  },
  emits: ['openModal', 'closeModal', 'onUserUpdated']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.form {
  flex-direction: column;
}
</style>