<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Populate Template</h1>
      <p class="subtitle">
        Fill out some variables on your templates.
      </p>
    </div>
    <div class="modal-card-body">
      <form class="form" action="" @submit.prevent="">
        <div class="form-group" v-for="key of Object.keys(fields)" :key="key">
          <label for="name" class="form-label">{{key}}</label>
          <input id="name" type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="fields[key]">
        </div>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="selectTemplate">Done</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopulateTemplateModal",
  props: {
    text: String
  },
  data() {
    return {
      fields: {}
    };
  },
  created() {
    this.findVariables();
  },
  methods: {
    selectTemplate() {
      let text = this.text;

      for (let fieldName of Object.keys(this.fields)) {
        const field = `{${fieldName}}`;
        text = text.replace(field, this.fields[fieldName]);
      }

      this.$emit('selectTemplate', text);
    },
    findVariables() {
      const matches = this.text.match(/{[^}]+}/g);
      
      for (let field of matches) {
        const fieldName = field.substring(1, field.length - 1);
        this.fields[fieldName] = "";
      }
    }
  },
  components: {},
  emits: ["openModal", "closeModal", "selectTemplate"],
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

textarea {
  resize: vertical;
}
</style>