<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">User Picture</h1>
    </div>
    <div class="modal-card-body">
      <img :src="getProfilePicture()" alt="User Picture" class="user-picture" @click="selectFile">
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptUpdatePicture">
        <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else-if="isChanged">Update Picture</span>
        <span v-else>Close</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { uploadUserProfilePicture } from '@/api';

export default {
  name: "UserPictureModal",
  props: {
    user: Object
  },
  data() {
    return {
      imageUrl: '',
      imageBlob: null,
      pending: false,
      isChanged: false
    }
  },
  mounted() {
    if (this.user.profilePictureKey) {
      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      this.imageUrl = `${publicImages}/${this.user.profilePictureKey}`;
    }
  },
  methods: {
    selectFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*'
      input.click();

      input.onchange = async (event) => {
        this.imageBlob = await this.resizeAndCropImage(event.target.files[0]);
        this.imageUrl = this.getBlobAsImageUrl(this.imageBlob);
        this.isChanged = true;
      }
    },
    getProfilePicture() {
      if (!this.imageUrl) {
        return '/icons/patient.png'
      }

      return this.imageUrl;
    },
    getBlobAsImageUrl(blob) {
      console.log(blob);
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    },
    async resizeAndCropImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            try {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              const size = 256;

              // Set canvas dimensions
              canvas.width = size;
              canvas.height = size;

              // Calculate cropping coordinates and dimensions
              const width = img.width;
              const height = img.height;
              let sourceX, sourceY, sourceWidth, sourceHeight;

              if (width > height) {
                sourceX = (width - height) / 2;
                sourceY = 0;
                sourceWidth = height;
                sourceHeight = height;
              } else {
                sourceX = 0;
                sourceY = (height - width) / 2;
                sourceWidth = width;
                sourceHeight = width;
              }

              // Draw the cropped image to the canvas
              context.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, size, size);

              // Convert canvas to Blob
              canvas.toBlob((blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('Canvas is empty'));
                }
              }, 'image/jpeg');
            } catch (error) {
              reject(error);
            }
          };
          img.onerror = reject;
          img.src = event.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async attemptUpdatePicture() {
      if (this.pending) {
        return;
      }

      if (!this.isChanged) {
        this.$emit('closeModal');
        return;
      }

      try {
        this.pending = true;
        const userId = this.user.id;
        const key = await uploadUserProfilePicture(userId, this.imageBlob);
        this.$emit('onUserPictureUpdated', userId, key);
      } catch (err) {
        console.error('Failed to upload file', err);
      } finally {
        this.pending = false;
      }
    }
  },
  emits: ['openModal', 'closeModal', 'onUserPictureUpdated']
};
</script>

<style scoped>
.modal-card {
  max-width: 400px;
}

.user-picture {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
}

.modal-card-footer {
  margin-top: 45px;
}
</style>