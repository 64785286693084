<template>
  <div class="patient-list-item">
    <img :src="getProfilePicture()" alt="Patient Icon" class="patient-icon">
    <div class="patient-description">
      <div class="row">
        <span class="patient-name">{{ getPatientName() }}</span>
        <div class="patient-phone">{{ getFormattedPhone() }}</div>
      </div>
      <div class="row">
        <span class="patient-dob">{{ patient.dateOfBirth }}</span>
        <div class="patient-email">{{ patient.emailAddress }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientListItem',
  props: {
    patient: Object
  },
  methods: {
    getPatientName() {
      const name = [
        this.patient.firstName,
        this.patient.middleName,
        this.patient.lastName
      ].filter(part => part).join(' ');
      return name;
    },
    getFormattedPhone() {
      const phone = this.patient.mobilePhoneNumber;
      if (!phone) return 'None'
      return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
    },
    getProfilePicture() {
      if (!this.patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${this.patient.profilePictureKey}`;
    }
  },
}
</script>

<style scoped>
.patient-list-item {
  width: 100%;
  display: flex;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
}

.patient-list-item:hover {
  background: var(--color-fill-med);
}

.patient-icon {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.patient-icon img {
  width: 25px;
  height: 25px;
  display: block;
}

.patient-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  flex: 1;
}

.patient-description .row {
  display: flex;
  justify-content: space-between;
}

.patient-name {
  font-weight: bold;
  flex: 1;
}

@media screen and (max-width: 1000px) {
  .row {
    flex-direction: column;
    gap: 5px;
  }
}
</style>