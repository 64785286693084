<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Archive Patient</h1>
      <p class="subtitle">Are you sure you want to archive <u>{{getPatientName()}}</u>'s patient profile? To recover it, you will need to contact DashQuill support.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="attemptArchivePatient">Delete</button>
    </div>
  </div>
</template>

<script>
import { archivePatient } from '../../api';

export default {
  name: "ArchivePatientModal",
  props: {
    patient: Object
  },
  methods: {
    async attemptArchivePatient() {
      try {
        const patientId = this.patient.id;
        await archivePatient(patientId);
        this.$emit('onPatientArchived', patientId);
      } catch (err) {
        console.error(err);
      }
    },
    getPatientName() {
      const pat = this.patient;
      const name = [
        pat.firstName,
        pat.middleName,
        pat.lastName
      ].filter(part => part).join(' ');
      return name;
    },
  },
  emits: ['onPatientArchived']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}

</style>