<template>
  <div class="form-filler" ref="filler">
    <div class="form-header">
      <img :src="logoSrc" alt="Logo" class="form-logo">
      <h1>{{ formName }}</h1>
    </div>
    <div class="form-section-container" v-for="section of sections" :key="section.id">
      <TextSection v-if="section.type == 'INFO'" ref="sections" :section="section" :isEditing="false" />
      <QuestionsSection v-else-if="section.type == 'QUESTIONS'" ref="sections" :section="section" :isEditing="false" />
      <CheckListSection v-else-if="section.type == 'CHECKLIST'" ref="sections" :section="section" :isEditing="false" />
      <SignatureSection v-else-if="section.type == 'SIGNATURE'" ref="sections" :section="section" :isEditing="false" />
    </div>
  </div>
</template>

<script>
import TextSection from './sections/TextSection.vue'
import QuestionsSection from './sections/QuestionsSection.vue';
import CheckListSection from './sections/CheckListSection.vue'
import SignatureSection from './sections/SignatureSection.vue';

export default {
  name: 'FormFiller',
  props: {
    formInfo: Object
  },
  data() {
    return {
      formName: 'Form',
      sections: [],
      logoSrc: '/logo-placeholder.png',
    }
  },
  mounted() {
    this.formName = this.formInfo.name;
    this.sections = this.formInfo.sections;

    if (this.formInfo.logoBase64) {
      this.logoSrc = this.formInfo.logoBase64;
    }
  },
  methods: {
  },
  components: { TextSection, QuestionsSection, CheckListSection, SignatureSection },
  emits: []
}
</script>

<style scoped>
.form-filler {
  width: 100%;
  max-width: 1200px;
  padding: 75px;
  background: #FFF;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 12px #00000022;
}

@media screen and (max-width: 1000px) {
  .form-filler {
    padding: 25px;
  }
}

.form-section-container {
  position: relative;
  width: 100%;
}

.form-section {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 60px;
}

.form-header {
  margin-bottom: 60px;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(100px);
}

.center .button {
  width: auto;
  box-shadow: 0px 0px 12px #00000022;
}

.form-logo {
  height: 75px;
  display: block;
  margin-bottom: 30px;
}
</style>

<style>
.form-section-content p {
  font-size: 18px;
  white-space: pre-wrap;
  line-height: 30px;
}

.form-section-content li {
  font-size: 18px;
  line-height: 30px;
}

.form-section-content a {
  color: rgb(0, 110, 255);
  word-break: break-all;
}
</style>