<template>
  <nav>
    <div class="navbar">
      <router-link to="/apps" id="logo">
        <img class="logo-lg" src="/logo.png" alt="DashQuill Logo" />
        <img class="logo-sm" src="/logo-sm.png" alt="DashQuill logo">
      </router-link>
      <div class="left">
        <router-link class="nav-link" to="/apps">My Apps</router-link>
        <router-link class="nav-link" to="/patients">Patients</router-link>
      </div>
      <div class="right">
        <div class="notif-info" ref="notifInfo" @click.prevent="openNotifPopup">
          <img src="/icons/bell.png" alt="Notification" class="notification-icon">
          <div class="notif-count" v-if="numUnread > 0">{{ numUnread }}</div>
          <transition name="modal-appear" mode="out-in">
            <div class="notifications-popup" v-if="notifPopupOpen">
              <div class="notification-header">
                <h3>Notifications</h3>
                <img src="/icons/close.png" alt="Close Icon" class="close-modal-icon"
                  @click.prevent.stop="closeNotifications()">
              </div>
              <div class="notification-list">
                <div class="notification" v-for="notif of notifications" :key="notif.id">
                  <img class="notif-icon" :src="getImageForNotif(notif)" alt="Notification Icon">
                  <div class="notif-body">
                    <span class="notif-message">{{ notif.message }}</span>
                    <span class="notif-time">{{ formatNotifTime(notif) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="user-info" ref="userInfo" v-if="user" @click.prevent="openPopup">
          <img class="user-profile-picture" :src="getProfilePicture()" alt="Profile Picture">
          <span class="user-name">{{ user.name }}</span>
          <transition name="modal-appear" mode="out-in">
            <div class="user-actions-popup" v-if="popupOpen">
              <div class="action-link" @click="openAccountSettings">
                <span>Account Settings</span>
              </div>
              <div class="action-link" @click="openUserSettings">
                <span>User Settings</span>
              </div>
              <div class="action-link" @click="logout" style="border-top: 1px solid var(--color-border-med);">
                <span>Logout</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { userInfo, userNotifications, userMarkNotificationsRead, unlinkFirebaseNotifications, logout } from '@/api'
import { Device } from '@capacitor/device';
import { isMobile } from '../mobile/MobileUtils.js';

export default {
  name: 'NavBar',
  data() {
    return {
      user: null,
      popupOpen: false,
      notifPopupOpen: false,
      notifications: [],
      numUnread: 0
    }
  },
  mounted() {
    this.attemptFetchUser();
    this.attemptFetchNotifications();

    window.addEventListener('click', this.onMouseDown);
    window.addEventListener('touchstart', this.onMouseDown);
  },
  unmounted() {
    window.removeEventListener('click', this.onMouseDown);
    window.removeEventListener('touchstart', this.onMouseDown);
  },
  methods: {
    getProfilePicture() {
      if (!this.user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${this.user.profilePictureKey}`;
    },
    openPopup() {
      this.popupOpen = true;
    },
    openNotifPopup() {
      this.notifPopupOpen = true;
      this.attemptMarkNotificationsRead();
    },
    openAccountSettings() {
      this.$router.push('/settings');
    },
    openUserSettings() {
      this.$router.push('/settings/users');
    },
    onMouseDown(event) {
      const target = event.target;

      const userInfoRef = this.$refs.userInfo;
      if (!userInfoRef || !userInfoRef.contains(target)) {
        this.popupOpen = false;
      }

      const notifInfoRef = this.$refs.notifInfo;
      if (!notifInfoRef || !notifInfoRef.contains(target)) {
        this.notifPopupOpen = false;
      }
    },
    formatNotifTime(notif) {
      const date = new Date(notif.dateCreated);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
    getImageForNotif(notif) {

      if (notif.service == 'Messaging') {
        return '/icons/app-messaging.png';
      }

      return '/icons/bell.png';
    },
    async logout() {
      try {
        const isDevMobile = await isMobile();
        if (isDevMobile) {
          const deviceId = await Device.getId();
          const deviceIdentifier = deviceId.identifier;
          if (deviceIdentifier) {
            await unlinkFirebaseNotifications(deviceIdentifier);
          }
        }
        await logout();
        this.$router.push('/login');
      } catch (err) {
        console.error('Failed to logout', err);
      }
    },
    async attemptFetchUser() {
      try {
        this.user = await userInfo();
      } catch (err) {
        console.error('Failed to fetch user info', err);
      }
    },
    async attemptFetchNotifications() {
      try {
        const { notifications, numUnread } = await userNotifications();
        this.notifications = notifications;
        this.numUnread = numUnread;
      } catch (err) {
        console.error('Failed to fetch user info', err);
      }
    },
    async attemptMarkNotificationsRead() {
      try {
        await userMarkNotificationsRead();
        this.numUnread = 0;
      } catch (err) {
        console.error(err);
      }
    },
    closeNotifications() {
      this.notifPopupOpen = false;
    }
  }
}
</script>

<style scoped>
nav {
  position: fixed;
  background: var(--color-fill-light);
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0px 8px #00000022;
  z-index: 1000;
  padding-top: calc(15px + env(safe-area-inset-top));
}

.navbar {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
}

.right {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 15px;
}

.nav-link {
  margin-left: 25px;
  color: #000;
  text-decoration: none;
}

.nav-link.is-active {
  text-shadow: 0px 0px 1px #000;
}

.nav-link img {
  display: block;
  height: 24px;
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.user-name {
  color: var(--color-txt-med);
}

.user-profile-picture {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
}

.user-actions-popup {
  cursor: default;
  position: absolute;
  z-index: 1001;
  top: calc(100% + 10px);
  right: 0;
  background: var(--color-fill-light);
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.action-link {
  display: block;
  text-wrap: nowrap;
  padding: 15px;
  cursor: pointer;
  color: var(--color-txt-dark);
}

.action-link:hover {
  background: var(--color-fill-med);
}

.notification-icon {
  height: 24px;
  display: block;
}

.notif-info {
  position: relative;
  cursor: pointer;
}

.notif-count {
  position: absolute;
  bottom: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
  color: #FFF;
  font-size: 10px;
}

.notifications-popup {
  cursor: default;
  position: absolute;
  z-index: 1001;
  top: calc(100% + 10px);
  right: 0;
  background: var(--color-fill-light);
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
}

@media screen and (max-width: 1000px) {
  .notifications-popup {
    position: fixed;
    top: calc(10px + env(safe-area-inset-top));
    left: 10px;
    width: calc(100% - 20px);
  }
}

.notification-header {
  padding: 20px;
  border-bottom: 1px solid #CCC;
}

.notif-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notification {
  display: flex;
  gap: 15px;
  padding: 15px;
  cursor: pointer;
  color: var(--color-txt-dark);
  width: 350px;
}

.notification:hover {
  background: var(--color-fill-med);
}

.notif-time {
  font-size: 14px;
  color: var(--color-txt-light);
}

.notif-message {
  display: block;
}

.notif-icon {
  height: 40px;
  display: block;
}

#logo {
  display: block;
  height: 30px;
}

.logo-lg {
  display: block;
  height: 100%;
}

.logo-sm {
  display: none;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .user-name {
    display: none;
  }

  .logo-lg {
    display: none;
  }

  .logo-sm {
    display: block;
  }
}
</style>