<template>
  <div class="chat-list-item" :data-selected="isChatSelected()">
    <img src="/icons/patient.png" alt="Patient Icon" class="chat-icon">
    <div class="chat-description">
      <span class="chat-name">{{ getPhoneNumber() }}</span>
      <span class="chat-time">{{ getFormattedDate() }}</span>
    </div>
    <div class="unresolved-icon" v-if="!isChatResolved()"></div>
  </div>
</template>

<script>
export default {
  name: 'PatientChatListItem',
  props: {
    chat: Object
  },
  methods: {
    getFormattedDate() {
      if (!this.chat.id) {
        return 'New Chat'
      }

      const date = new Date(this.chat.dateLastUpdated);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
    getPhoneNumber() {
      const phoneNumber = this.chat.mobilePhoneNumber;
      const digits = phoneNumber.replace(/[^0-9]/g, '');
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
    },
    isChatSelected() {
      const route = this.$route.params;
      const chatId = route.id;
      return this.chat.id == chatId;
    },
    isChatResolved() {
      if (!this.chat.id) {
        return true;
      }

      return this.chat.isResolved;
    }
  }
}
</script>

<style scoped>
.chat-list-item {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-top: 1px solid var(--color-border-light);
}

.chat-list-item[data-selected="true"] {
  background: var(--color-fill-med);
}

.chat-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon img {
  display: block;
  width: 25px;
  height: 25px;
}

.chat-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-name {
  font-weight: bold;
}

.chat-time {
  color: #666;
}

.unresolved-icon {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 15px;
  width: 10px;
  height: 10px;
  background: #6d3cff;
  border-radius: 50%;
  z-index: 100;
}
</style>