import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { addListeners, registerNotifications, getNotificationPermissions, getDeliveredNotifications, attemptToLink } from './mobile/PushNotification.js'
import VTooltip from 'v-tooltip'


const tooltipOptions = {
  distance: 12,
  themes: {
    tooltip: {
      delay: {
        show: 500,
        hide: 0
      }
    }
  }
}

const enableHttp = async () => {
  if (Capacitor.getPlatform() === 'ios') {
    await CapacitorHttp.configure({ // Use Cap HTTP which will switch native fetch for ios 
      ios: {
        enableNative: true
      }
    });
  }
};

enableHttp();


// Set up push notifications
(async () => {
  try {
    await addListeners();
    await registerNotifications();
    await getNotificationPermissions();
    await getDeliveredNotifications();
    await attemptToLink();
  } catch (error) {
    console.error('Error setting up push notifications:', error);
  }
})();

// createApp(App).use(router).mount('#app')

const app = createApp(App);
app.use(router);
app.use(VTooltip);
app.use(FloatingVue, tooltipOptions);
app.mount('#app');
