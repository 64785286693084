<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Messaging Settings</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptUpdateSettings">
        <div class="form-group">
          <label class="form-label">Service Status</label>
          <select type="text" class="form-control" v-model="status">
            <option :value="false">Disabled</option>
            <option :value="true">Enabled</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">SMS Sender Number</label>
          <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="phone">
        </div>
        <div class="form-group">
          <label class="form-label">Opt In Message</label>
          <textarea type="text" ref="text" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="optInMessage" @input="resizeTextArea"></textarea>
        </div>
        <div class="form-group">
          <label class="form-label">Sender Email</label>
          <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="email">
        </div>
        <input type="submit" value="" hidden>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptUpdateSettings">Save Changes</button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { editAdminMessagingSettings, getAdminMessagingSettings } from '@/api'

export default {
  name: "AccountMessagingModal",
  props: {
    account: Object
  },
  data() {
    return {
      status: this.account.isMessagingEnabled ? true : false,
      phone: '',
      optInMessage: '',
      email: '',
      loading: false,
      error: ''
    }
  },
  mounted() {
    this.attemptFetchSettings();

    setTimeout(() => {
      this.resizeTextArea();
    }, 250);
  },
  methods: {
    async attemptFetchSettings() {
      try {
        const settings = await getAdminMessagingSettings(this.account.id);
        this.phone = settings.smsSenderNumber;
        this.optInMessage = settings.optInMessage;
        this.email = settings.senderEmail;
      } catch (err) {
        this.error = err.message;
      }
    },
    async attemptUpdateSettings() {
      try {
        this.loading = true;
        await editAdminMessagingSettings(this.account.id, this.status, this.phone, this.optInMessage, this.email);
        this.$emit('onMessagingToggled', this.account.id, this.status);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    resizeTextArea() {
      const target = this.$refs.text;
      if (!target) return;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    }
  },
  emits: ['openModal', 'closeModal', 'onMessagingToggled']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.form {
  flex-direction: column;
}

textarea {
  resize: none;
  overflow: hidden;
}
</style>