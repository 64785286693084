<template>
  <div class="panel">
    <div class="actions-bar">
      <router-link to="/settings">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <h2 style="flex: 1">Users</h2>
      <button class="button small primary" @click="$emit('openModal', 'invite-user')">Invite User</button>
    </div>
    <UserListTable :users="users" :invites="invitations" @onEditUser="onEditUser" @onEditPicture="onEditPicture"
      @onRemoveUser="onRemoveUser" @onRevokeInvite="onRevokeInvite" />
  </div>
</template>

<script>
import { fetchAllUsers, listInvitations } from '@/api'
import UserListTable from './UserListTable.vue'

export default {
  name: 'UserListPanel',
  data() {
    return {
      users: [],
      invitations: [],
      query: ""
    }
  },
  mounted() {
    this.attemptFetchUsers();
  },
  methods: {
    async attemptFetchUsers() {
      try {
        this.users = await fetchAllUsers();
        this.invitations = await listInvitations();
      } catch (err) {
        console.error('Failed to fetch all users', err);
      }
    },
    handleUserInvited(invitation) {
      this.invitations.push(invitation);
    },
    handleUserRemoved(userId) {
      this.users = this.users.filter(user => user.id != userId);
    },
    handleInviteRevoked(invitationId) {
      this.invitations = this.invitations.filter(invite => invite.id != invitationId);
    },
    handleUserUpdated(user) {
      // Replace the user based on ID
      this.users = this.users.map(usr => {
        if (usr.id != user.id) return usr;
        return user;
      })
    },
    handleUserPictureUpdated(userId, pictureKey) {
      const user = this.users.find(usr => usr.id == userId);
      if (!user) return;
      user.profilePictureKey = pictureKey;
    },
    onEditUser(user) {
      this.$emit('openModal', 'edit-user', { user })
    },
    onEditPicture(user) {
      this.$emit('openModal', 'user-picture', { user })
    },
    onRemoveUser(user) {
      this.$emit('openModal', 'remove-user', { user })
    },
    onRevokeInvite(invite) {
      this.$emit('openModal', 'revoke-invite', { invite })
    }
  },
  components: { UserListTable },
  emits: ['openModal']
}
</script>

<style scoped>
.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.table {
  height: 1px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  padding: 15px 25px;
  padding-left: 100px;
  padding-right: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 25px;
  position: relative;
  z-index: 100;
}

.column-header {
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.user-list-items {
  height: 1px;
  flex: 1;
  overflow-y: auto;
}

.actions-bar {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .back-icon {
    display: block;
  }
}
</style>