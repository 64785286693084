<template>
  <div class="account-list-table" @scroll="onScroll">
    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th width="25%" style="padding-left: 100px">Practice Name</th>
          <th width="20%">Practice Type</th>
          <th width="20%">Practice EMR</th>
          <th width="20%">Enabled Services</th>
          <th width="15%">Date Joined</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="account of accounts" :key="account.id" @click="$emit('openAccountInfo', account)">
          <td class="column-practice-name">
            <img src="/icons/company.png" alt="Company Picture" class="company-picture">
            <span class="practice-name">{{ account.name }}</span>
          </td>
          <td class="column-practice-type">
            <span class="practice-type">{{ account.practiceType }}</span>
          </td>
          <td class="column-practice-emr">
            <span class="practice-emr">{{ account.practiceEMR }}</span>
          </td>
          <td class="column-active-sevices">
            <img src="/icons/app-messaging.png" class="icon-sm" alt="Messaging" :data-enabled="account.isMessagingEnabled" @click.stop.prevent="$emit('onEditMessaging', account)">
            <img src="/icons/app-meetings.png" class="icon-sm" alt="Meetings" :data-enabled="account.isMeetingsEnabled" @click.stop.prevent="$emit('onEditMeetings', account)">
          </td>
          <td class="column-date-joined">
            <span class="date-joined">{{ getFormattedDate(account.dateJoined) }}</span>
          </td>
          <td>
            <img src="/icons/trash.png" class="remove-account-icon" alt="Remove Account Buttton" @click.stop.prevent="$emit('onRemoveAccount', account)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AccountListTable',
  props: {
    accounts: Array
  },
  methods: {
    getFormattedDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
  },
  emits: ['openAccountInfo', 'onRemoveAccount', 'onEditMessaging']
}
</script>

<style scoped>
.account-list-table {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-light);
  border-radius: 5px;
  overflow: auto;
}

table {
  min-width: 100%;
  table-layout: fixed;
}

thead {
  position: sticky;
  top: 0;
  background: var(--color-fill-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

th {
  height: 50px;
  padding: 0px 25px;
  text-align: left;
  font-weight: 400;
  border: 1px solid var(--color-border-med);
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  background: var(--color-fill-med);
}

th:not(:first-child) {
  border-left: none;
}

th:not(:last-child) {
  border-right: none;
}

td {
  height: 70px;
  padding: 0px 25px;
}

.column-practice-name {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: bold;
}

.company-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.remove-account-icon {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.2;
}

.remove-account-icon:hover {
  opacity: 1;
}

.icon-sm {
  display: inline-block;
  width: 32px;
  height: 32px;
  filter: saturate(0);
  margin-right: 10px;
}

.icon-sm[data-enabled="true"] {
  filter: none;
}
</style>