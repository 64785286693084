<template>
  <div class="panel">
    <div class="panel-header">
      <router-link to="/settings">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <h2>General</h2>
    </div>
    <div class="panel-body form" v-if="account">
      <div class="form-group">
        <label for="" class="form-label">Practice Name</label>
        <input class="form-control" :value="account.name" disabled>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Practice Type</label>
        <input class="form-control" :value="account.practiceType" disabled>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Selected EHR</label>
        <input class="form-control" :value="account.practiceEMR" disabled>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Member Since</label>
        <input class="form-control" :value="getFormattedDate(account.dateJoined)" disabled>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Primary User</label>
        <input class="form-control" :value="account.ownerName" disabled>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAccountInfo } from '../../api'
export default {
  name: 'MessagingSettingsPanel',
  data() {
    return {
      account: null,
      error: ''
    }
  },
  mounted() {
    this.attemptFetchAccount();
  },
  methods: {
    async attemptFetchAccount() {
      try {
        this.account = await fetchAccountInfo();
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },
    getFormattedDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString();
    }
  },
}
</script>

<style scoped>
.form {
  max-width: 400px;
}

.panel {
  flex: 1;
}

p {
  background: none;
}

.panel-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .back-icon {
    display: block;
  }
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
}
</style>