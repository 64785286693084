import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';

const registerNotifications = async () => {
    await PushNotifications.addListener('registration', async token => {
        // Get additional device information
        const deviceInfo = await Device.getInfo();
        const deviceId = await Device.getId();
        let platform = '';

        switch (deviceInfo.platform) {
            case "android":
                platform = "Android";
                break;
            case "ios":
                platform = "iOS";
                break;
            case "web":
                platform = "Web";
                break;
        }

        console.log('FCM token:', token.value, 'deviceId:', deviceId.identifier, 'deviceName:', deviceInfo.name);

        // Send the FCM token to your backend
        await sendTokenToBackend({
            token: token.value,
            deviceId: deviceId.identifier,
            platform: platform,
            deviceName: deviceInfo.name
        });
        console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
    });
}

const addListeners = async () => {
    await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
}

const getNotificationPermissions = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    console.log("Perm Status", permStatus);
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
}

const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
}

const sendTokenToBackend = async ({ token, deviceId, platform, deviceName }) => {
    const obj = JSON.stringify({ token, deviceId, platform, deviceName });
    console.log('Sending token to backend:', obj);

    try {
        const response = await fetch(process.env.VUE_APP_API + '/core/firebase/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: obj,
        });

        if (!response.ok) {
            throw new Error('Failed to send token to backend');
        }

        const data = await response.json();
        console.log('Backend response:', data);
    } catch (error) {
        console.error('Error sending token to backend:', error);
    }
};

const attemptToLink = async() => {
    try {
        const deviceId = await Device.getId();
        const response = await fetch(process.env.VUE_APP_API + '/core/firebase/link', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include',
            },
            body: JSON.stringify({ deviceId: deviceId.identifier }),
        });

        if (!response.ok) {
            throw new Error('Failed to send token to backend');
        }

        const data = await response.json();
        console.log('Backend response:', data);
    } catch (error) {
        console.error('Error attempting to link device:', error);
    }
}

export { registerNotifications, addListeners, getNotificationPermissions, getDeliveredNotifications, attemptToLink };
