<template>
  <div class="form-list-item" :data-selected="isFormSelected()">
    <div class="form-icon">
      <img src="@/assets/icons/form.png" alt="Form Icon">
    </div>
    <div class="form-description">
      <span class="form-name">{{ form.name }}</span>
      <span class="form-time">Some subtext</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormListItem',
  props: {
    form: Object
  },
  methods: {
    isFormSelected() {
      const route = this.$route.params;
      const formId = route.id;
      return this.form.id == formId;
    },
  }
}
</script>

<style scoped>
.form-list-item {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
  text-decoration: none;
}

.form-list-item[data-selected="true"] {
  background: var(--color-fill-med);
}

.form-icon {
  padding: 5px;
}

.form-icon img {
  height: 40px;
  display: block;
}

.form-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-name {
  font-weight: bold;
}

.form-time {
  color: #666;
}
</style>