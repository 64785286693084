<template>
  <div class="modal-card">
      <div class="modal-card-header">
        <h1 class="title">Leave Group Chat</h1>
        <p class="subtitle">Are you sure you want to leave the chat <u>{{chatInfo.name}}</u>?</p>
      </div>
      <div class="modal-card-footer">
        <button class="button danger" @click.prevent="attemptLeave">
          <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Leave Chat</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
  </div>
</template>

<script>
import { groupChatLeaveChat } from "@/api";

export default {
  name: "LeaveGroupChatModal",
  props: {
    chatInfo: Object
  },
  data() {
    return {
      error: "",
      pending: false,
    };
  },
  methods: {
    async attemptLeave() {
      try {
        this.pending = true;
        await groupChatLeaveChat(this.chatInfo.id);
        this.$emit("closeModal");
        this.$router.push('/messaging/groupchats');
      } catch (err) {
        console.error("Failed to leave chat", err);
      } finally {
        this.pending = false;
      }
    },
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>