<template>
  <div class="edit-section-bar">
    <div class="btn-list">
      <div class="btn" @click.prevent.stop="editSection">
        <img class="btn-icon" src="@/assets/icons/edit.png" alt="Edit Section">
      </div>
      <div class="btn danger" @click.prevent.stop="deleteSection">
        <img class="btn-icon" src="@/assets/icons/trash.png" alt="Delete Section">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditSectionBar',
  methods: {
    editSection() {
      this.$emit('onEditSection')
    },
    deleteSection() {
      this.$emit('onDeleteSection')
    }
  },
  emits: ['onEditSection', 'onDeleteSection']
}
</script>

<style scoped>
.edit-section-bar {
  position: absolute;
  right: -75px;
  width: 75px;
  top: 0;
  height: 100%;
}

.btn-list {
  margin-left: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-left: 1px dashed var(--color-border-med);
}

.btn {
  padding: 8px;
  cursor: pointer;
  background: var(--color-fill-med);
  border-radius: 10px;
  border: 1px solid var(--color-border-med);
}

.btn.danger {
  background: #b14040;
  border: 1px solid #802020;
}

.btn-icon {
  width: 16px;
  height: 16px;
  display: block;
  pointer-events: none;
}

.btn.danger .btn-icon {
  filter: invert(1)
}
</style>