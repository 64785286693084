<template>
  <div class="form-section questions-section" ref="section" :data-is-editing="isEditing">
    <div class="form-section-editor" v-if="isEditing">
      <input class="section-title" type="text" v-model="title" placeholder="Enter section title...">
      <textarea class="section-subtitle" ref="text" type="text" @input="resizeTextArea" v-model="subtitle" placeholder="Enter section subtitle..." />
      <div class="signature-row">
        <div class="field signature-field">
          <label for="" class="field-label">Patient Signature</label>
          <canvas class="field-control signature-canvas"></canvas>
        </div>
        <div class="signature-details">
          <div class="field">
            <label for="">Name</label>
            <input type="text" class="form-control">
          </div>
          <div class="field">
            <label for="">Today's Date</label>
            <input type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="form-section-content" v-else>
      <h2 class="section-title" v-if="section.title">{{ section.title }}</h2>
      <p class="section-subtitle" v-if="section.subtitle">{{ section.subtitle }}</p>
      <div class="signature-row">
        <div class="field signature-field">
          <label for="" class="field-label">Patient Signature</label>
          <canvas class="field-control signature-canvas"></canvas>
        </div>
        <div class="signature-details">
          <div class="field">
            <label for="">Name</label>
            <input type="text" class="field-control">
          </div>
          <div class="field">
            <label for="">Today's Date</label>
            <input type="text" class="field-control">
          </div>
        </div>
      </div>
    </div>
    <transition mode="out-in" name="modal-fade">
      <div class="form-section-editor-overlay" v-if="isEditing"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SignatureSection',
  props: {
    section: Object,
    isEditing: Boolean
  },
  data() {
    return {
      title: this.section.title,
      subtitle: this.section.subtitle,
      label: 'Patient Signature',
    }
  },
  watch: {
    isEditing: {
      handler: function (isNowEditing) {
        if (isNowEditing) {
          this.$nextTick(() => {
            this.resizeTextArea();
          })
        } else {
          this.onUpdateSection();
        }
      },
      immediate: true
    }
  },
  methods: {
    resizeTextArea() {
      const textAreaRef = this.$refs.text;
      if (textAreaRef) {
        textAreaRef.style.height = '0px';
        this.$nextTick(() => {
          textAreaRef.style.height = `${Math.max(30, textAreaRef.scrollHeight)}px`
        })
      }
    },
    onUpdateSection() {
      const newSection = structuredClone(this.section);
      newSection.title = this.title;
      newSection.subtitle = this.subtitle;
      console.info('Updating section', newSection);
      this.$emit('onUpdateSection', newSection);
    },
    onBlurQuestion() {
      this.questions = this.questions.filter(q => {
        return q.label.length > 0;
      })
    }
  },
  emits: ['onUpdateSection']
}
</script>

<style scoped>
.form-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signature-row {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
}

.signature-field {
  flex: 2;
}

.signature-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.field-label {
  font-size: 16px;
  color: var(--color-txt-med);
  text-wrap: nowrap;
}

.field-control {
  width: 100%;
  padding: 10px;
  border: none;
  background: var(--color-fill-med);
  border-radius: 2px;
  font-size: 18px;
  border-bottom: 1px solid var(--color-border-dark);
}

.field-control:focus {
  outline: 1px solid var(--color-border-dark);
}

h2 {
  margin-bottom: 32px;
}

th {
  text-align: left;
  padding: 10px;
}

tr:nth-child(odd) {
  background: var(--color-fill-med);
}

td span {
  display: block;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  background: transparent;
}

td>.container {
  margin-left: 10px;
  display: block;
}

table {
  width: 100%;
}

.section-subtitle {
  display: block;
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 0px;
  line-height: 30px;
  margin-bottom: 30px;
}

input::placeholder {
  opacity: 0.5;
}

textarea {
  background: transparent;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
}

textarea::placeholder {
  opacity: 0.5;
}

.signature-canvas {
  width: 100%;
  height: 140px;
}
</style>