<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Delete User</h1>
      <p class="subtitle">Are you sure you want to delete <u>{{user.name}}</u> from your account? All their settings and history will be erased.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="attemptRemoveUser">Delete</button>
      <span class="error" v-if="error">{{error}}</span>
    </div>
  </div>
</template>

<script>
import {removeUser} from '@/api'

export default {
  name: "RemoveUserModal",
  props: {
    user: Object
  },
  data() {
    return {
      pending: false,
      error: ""
    }
  },
  methods: {
    async attemptRemoveUser() {
      try {
        this.pending = true;
        const userId = this.user.id;
        await removeUser(userId);
        this.$emit('onUserRemoved', userId);
      } catch (err) {
        this.error = err.message;
        console.error(err);
      } finally {
        this.pending = false;
      }
    },
  },
  emits: ['onUserRemoved']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}

</style>