<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h1 class="title">Manually Opt-in Patient</h1>
      <p class="subtitle">This patient has not opted into SMS messages. By clicking the buttom below, you are confirming that you have received, either verbally or in writing, consent from the patient to recieve text messages from your office.</p>
    </div>
    <div class="modal-card-footer" style="margin-top: 0">
      <button class="button primary" @click="attemptOptIn">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Opt In Patient</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { toggleOptIn } from '@/api'

export default {
  name: "ManualOptInModal",
  props: {
    patientId: String
  },
  data() {
    return {
      loading: false,
      error: '',
    }
  },
  methods: {
    async attemptOptIn() {
      if (this.loading) return;

      try {
        this.loading = true;
        await toggleOptIn(this.patientId, true);
        this.$emit('onOptedIn')
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['closeModal', 'onOptedIn']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>