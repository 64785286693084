<template>
  <div id="full">
    <div class="modal-card">
      <div class="modal-card-header">
        <h1 class="title">Join Online Meeting</h1>
        <p class="subtitle">Please enter the 6 letter code for your online appointment with your provider at <strong>{{ account.name }}</strong>. If you do not
          know the code, please contact your provider.</p>
      </div>
      <div class="modal-card-body">
        <transition name="modal-appear" mode="out-in">
          <char-code-input v-if="meeting == null" :initialChars="code" :length="6" @updateValue="onUpdateValue" />
          <div class="meeting-info" v-else>
            <h3>{{ meeting.patient.firstName }} {{ meeting.patient.lastName }}'s Meeting</h3>
            <p>{{ getFormattedDate() }} · {{ getFormattedTime() }}</p>
            <hr>
            <div class="user-icons" v-if="meeting.expectedUsers.length > 0">
              <img class="user-picture" :src="getProfilePicture(user)" :title="user.name" :alt="user.name"
                v-for="user of meeting.expectedUsers" :key="user.id">
            </div>
          </div>
        </transition>
      </div>
      <div class="modal-card-footer">
        <button class="button primary" @click="attemptJoinMeeting">Join Meeting</button>
        <p class="error" v-if="error">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CharCodeInput from '../components/CharCodeInput.vue'
import { meetJoinMeeting, meetsInfo, accountOfficeNameInfo } from '../api';

export default {
  name: 'JoinMeetingView',
  data() {
    return {
      account: {},
      code: '',
      meeting: null,
      error: ''
    }
  },
  created() {
    const name = this.$route.params.name;
    this.attemptFetchAccount(name);

    const code = this.$route.query.code;
    if (code) {
      this.code = code;
    }
  },
  methods: {
    onUpdateValue(code) {
      this.code = code;

      if (code.length == 6) {
        this.attemptFetchMeeting();
      }
    },
    async attemptFetchAccount(name) {
      try {
        this.account = await accountOfficeNameInfo(name);

        if (this.code.length == 6) {
          this.attemptFetchMeeting();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async attemptFetchMeeting() {
      this.meeting = null;
      this.error = '';

      try {
        this.meeting = await meetsInfo(this.account.id, this.code);
        console.info('Fetched meeting info', this.meeting);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },
    async attemptJoinMeeting() {
      if (!this.meeting) {
        this.error = "Please enter the meeting code for this meeting"
        return;
      }

      try {
        const meetingLink = await meetJoinMeeting(this.account.id, this.code);
        window.location.replace(meetingLink);
        console.info('Fetched meeting info', meetingLink);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getPatientProfilePicture(patient) {
      if (!patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${patient.profilePictureKey}`;
    },
    getFormattedDate() {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const inputDate = new Date(this.meeting.meetingTime);
      const today = new Date();

      const isSameDay = (date1, date2) => date1.toDateString() === date2.toDateString();

      // Check if the input date is today
      if (isSameDay(inputDate, today)) {
        return "Today";
      }

      // Check if the input date is tomorrow
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      if (isSameDay(inputDate, tomorrow)) {
        return "Tomorrow";
      }

      // Check if the input date is within this week
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      if (inputDate >= startOfWeek && inputDate <= endOfWeek) {
        return daysOfWeek[inputDate.getDay()];
      }

      // If not within this week, return formatted as "Month Day"
      return `${monthsOfYear[inputDate.getMonth()]} ${inputDate.getDate()}`;
    },
    getFormattedTime() {
      const date = new Date(this.meeting.meetingTime);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';

      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;

      const formattedTime = `${hours}:${minutes} ${ampm}`;
      return formattedTime;
    },
  },
  components: { CharCodeInput }
}
</script>

<style scoped>
#full {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: unset;
}

.modal-card {
  max-width: 550px;
}

.meeting-info {
  border: 1px solid #CCC;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.user-picture,
.patient-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

hr {
  background-color: #EEE;
  height: 1px;
  border: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>