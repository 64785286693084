<template>
  <div class="panel">
    <div class="panel-body">
      <div class="button-list">
        <button class="button primary"
          @click.prevent="$emit('openModal', 'select-patient', { mode: 'scheduled' })">Schedule Meeting</button>
        <button class="button secondary"
          @click.prevent="$emit('openModal', 'select-patient', { mode: 'instant' })">Instant Meeting</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficeMeetingsPanel',
  emits: ['openModal']
}
</script>

<style scoped>
.panel {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.button-list {
  display: flex;
  gap: 15px;
}

@media screen and (max-width: 1000px) {
  .panel {
    height: unset;
    flex: unset;
  }
}
</style>