<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Meetings Settings</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptUpdateSettings">
        <div class="form-group">
          <label class="form-label">Service Status</label>
          <select type="text" class="form-control" v-model="status">
            <option :value="false">Disabled</option>
            <option :value="true">Enabled</option>
          </select>
        </div>
        <input type="submit" value="" hidden>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptUpdateSettings">Save Changes</button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { editAdminMeetingsSettings } from '@/api'

export default {
  name: "AccountMessagingModal",
  props: {
    account: Object
  },
  data() {
    return {
      status: this.account.isMeetingsEnabled ? true : false,
      phone: '',
      optInMessage: '',
      loading: false,
      error: ''
    }
  },
  methods: {
    async attemptUpdateSettings() {
      try {
        this.loading = true;
        await editAdminMeetingsSettings(this.account.id, this.status);
        this.$emit('onMeetingsToggled', this.account.id, this.status);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['openModal', 'closeModal', 'onMeetingsToggled']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.form {
  flex-direction: column;
}

textarea {
  resize: none;
  overflow: hidden;
}
</style>