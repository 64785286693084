<template>
    <div class="modal-card">
        <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
        <div class="modal-card-header">
            <h1 class="title">Instant Meeting</h1>
            <p class="subtitle"> Click the button below to join the instant meeting.</p>
        </div>
        <div class="modal-card-body">
            <div class="row row-control">
                <button class="button primary primary" @click.prevent="attemptJoinRoom">Join Instant Meeting</button>
            </div>
        </div>
    </div>
</template>

<script>
import { meetJoinMeetingProtected } from '../api';
export default {
    name: "ShowInstantMeetingModal",
    props: {
        meeting: Object,
    },
    data() {
        return {
        };
    },
    created() {
    },
    methods: {
        async attemptJoinRoom() {
            try {
                console.log('meeting', this.meeting);
                const meetingLink = await meetJoinMeetingProtected(this.meeting.roomName);
                const link = document.createElement('a');
                link.href = meetingLink;
                link.target = '_blank';
                link.click();
                this.$emit('closeModal');
            } catch (err) {
                console.error('Failed to join room', err);
            }
        }
    },
    components: {},
    emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.row-control {
    display: flex;
    justify-content: space-between;
}

.form-control {
    flex: 1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.modal-card {
    max-width: 500px;
}

.user-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.user-icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.user-picture,
.patient-picture {
    width: 40px;
    height: 40px;
    background: #EEE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.modal-card-body {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

textarea {
    resize: none;
    height: 100%;
}

.icons-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

@media screen and (max-width: 1000px) {
    .desc-row {
        flex-direction: column;
    }
}

.reminder-list strong {
    display: block;
    margin-bottom: 10px;
}

.reminder-list-items {
    display: flex;
    flex-direction: column;
}

.reminder-list-item {
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    padding: 5px;
}

.reminder-list-item img {
    height: 16px;
    display: block;
}
</style>