<template>
  <div class="chat-message-ref" :data-from-self-side="fromSelfSide" :style="{ maxWidth: getMaxWidth() + 'px' }">
    <div class="message-content" v-if="refMessage">
      <div class="author-column">
        <img :src="getProfilePicture()" alt="Author Picture" class="author-picture">
      </div>
      <div class="message-column" @click="forwardUserToPatientChatIfApplicable()">
        <div class="message-body">
          <span class="message-text" v-if="refMessage.fileContent">{{ refMessage.fileContent.name }}</span>
          <span class="message-text" v-else>{{refMessage.textContent}}</span>
        </div>
        <span class="message-footer">{{ refMessage.sender.name }} • {{ getMessageDate() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import { patientChatMessageInfo } from '@/api';

export default {
  name: 'ChatMessageRef',
  props: {
    messageRefContent: String,
    messageRefContentType: String,
    fromSelfSide: Boolean,
    chatWidth: Number
  },
  data() {
    return {
      refMessage: null
    }
  },
  mounted() {
    this.fetchRefMessage();
  },
  methods: {
    formatText(text) {
      let sanitizedText = DOMPurify.sanitize(text);
      // Replace new lines with <br> tags for HTML rendering
      return sanitizedText.replace(/\n/g, '<br>')        // Replace newlines with <br> tags
        .replace(/\r/g, '')            // Remove carriage returns (commonly found in Windows-style line endings)
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // Replace tabs with 4 non-breaking spaces
        .replace(/ {2}/g, '&nbsp;&nbsp;'); // Replace consecutive spaces with non-breaking spaces
    },
    forwardUserToPatientChatIfApplicable() {
      if (!this.refMessage) return;
      if (!this.refMessage.id) return;
      if (!this.refMessage.sender) return;
      if (this.refMessage.sender.type !== "Patient") return;
      if (!this.refMessage.sender.id) return;
      console.log('refmsg', this.refMessage);

      this.$router.push(`/messaging/patients/${this.refMessage.sender.id}?ref=${this.refMessage.id}`)
    },
    async fetchRefMessage() {
      try {
        this.refMessage = await patientChatMessageInfo(this.messageRefContent);
        console.log('this.refMsg', this.refMessage);
      } catch (err) {
        console.error(err);
      }
    },
    getMaxWidth() {
      return Math.min(600, this.chatWidth * 0.66);
    },
    getProfilePicture() {

      if (this.refMessage && this.refMessage.sender.profilePictureKey) {
        const key = this.refMessage.sender.profilePictureKey
        const publicImages = process.env.VUE_APP_PUBLIC_FILES;
        return `${publicImages}/${key}`;
      }

      return '/icons/patient.png'
    },
    getMessageDate() {
      const date = new Date(this.refMessage.dateCreated);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

      // Check if the date is today
      if (date.toLocaleDateString() === today.toLocaleDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday";
      }

      // Check if the date is within the last week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      if (today - date < 7 * oneDay) {
        return dayOfWeek;
      }

      // If more than a week ago, return MM/DD/YYYY format
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
  }
}
</script>

<style scoped>
.chat-message-ref {
  padding: 4px;
  border-radius: 18px;
  min-height: 48px;
  line-height: 24px;
  background: #EEE;
  cursor: pointer;
  display: flex;
}

.chat-message-ref[data-from-self-side="true"] {
  border-bottom-right-radius: 5px;
  background: var(--color-primary);
}

.chat-message-ref[data-from-self-side="false"] {
  border-bottom-left-radius: 5px;
}

.author-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
}

.message-content {
  display: flex;
  gap: 10px;
  background: #FFF;
  padding: 10px;
  border-radius: 14px;
  box-shadow: 0px 0px 4px #00000022;
}

.message-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.message-body {
  background: #EEE;
  padding: 12px;
  border-radius: 18px;
  border-bottom-left-radius: 5px;
  min-height: 48px;
  line-height: 24px;
}

.message-footer {
  font-size: 14px;
  color: #000;
  opacity: 0.7;
}

span {
  white-space: pre;
  word-break: break-all;
  text-wrap: wrap;
  max-width: 100%;
}
</style>