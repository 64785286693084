<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h1 class="title">Connect Patient</h1>
      <p class="subtitle">Looks like this patient is not in your DashQuill account. Do you want to add them and start a chat?</p>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="requestProfileInfo">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Start Chat</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { createPatient } from '@/api';

export default {
  name: "SyncPatientModal",
  data() {
    return {
      loading: false,
      error: '',
    }
  },
  mounted() {
    window.addEventListener('message', this.handleMessageReceived);
  },
  unmounted() {
    window.removeEventListener('message', this.handleMessageReceived);
  },
  methods: {
    async handleMessageReceived(e) {
      try {
        // Parse the message we received
        const data = JSON.parse(e.data);
        const {event, params} = data;
        
        // We should get this event
        if (event == 'patientProfileInfo') {

          // Get the profile from the event params
          const profile = params.profile;
          if (!profile) throw Error('Expected a profile');
          console.info('Received profile', profile);

          // Attempt to create the patient
          const patient = await createPatient(profile.firstName, profile.middleName, profile.lastName, profile.dateOfBirth,
            profile.mobilePhoneNumber, profile.emailAddress, profile.emrIntegrationId)

          // Emit the patient ID that was created
          this.$emit('onPatientImported', patient.id);
        }
        
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    requestProfileInfo() {
      if (this.loading) return;
      this.loading = true;
      window.top.postMessage(JSON.stringify({ event: 'requestProfileInfo' }), '*');
    }
  },
  emits: ['closeModal', 'onPatientImported']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>