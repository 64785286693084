<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h1 class="title">Unlock Secure Chat</h1>
      <p class="subtitle">To view private messages from your provider, please answer the verification questions.</p>
    </div>
    <div class="modal-card-body">
      <form action="" @submit.prevent="attemptAuth">
        <div class="form-group">
          <label for="" class="form-label">Date of Birth</label>
          <input type="text" class="form-control" placeholder="MM/DD/YYYY" v-model="dateOfBirth"
            @input="formatDateOfBirth">
        </div>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptAuth">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Login</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { patientLogin, urlToPatientId } from '@/api'

export default {
  name: "AuthPatientModal",
  data() {
    return {
      loading: false,
      error: '',
      dateOfBirth: ''
    }
  },
  methods: {
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    },
    async attemptAuth() {
      const privateURL = this.$route.params.url;
      const dateOfBirth = this.dateOfBirth;

      try {
        this.loading = true;
        this.error = '';

        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        console.info('Attempting to authenticate patient', { privateURL, dateOfBirth });
        const patientId = await urlToPatientId(privateURL);
        await patientLogin(patientId, dateOfBirth);
        this.$emit('patientLogin');

      } catch (err) {
        console.error('Failed to authenticate patient', err);
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['patientLogin']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>