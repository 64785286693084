<template>
  <div class="chat-message" :data-from-self-side="fromSelfSide" :id="message.id">
    <div class="message-date-strip" v-if="isFirstOfDay">
      <span class="message-date">{{ getMessageDate() }}</span>
    </div>
    <div class="message-content">
      <div class="author-column" v-if="shouldShowPicture()">
        <div class="author-picture" v-if="isFirstOfKind">
          <img :src="profilePicture" class="author-picture" alt="Author Picture">
        </div>
      </div>
      <div class="message-column">
        <div class="message-body">
          <ChatMessageRef v-if="message.messageRefContent != null" :messageRefContent="message.messageRefContent"
            :messageRefContentType="message.messageRefContentType" :fromSelfSide="fromSelfSide"
            :chatWidth="chatWidth" />
          <ChatMessageFile v-else-if="message.fileContent != null" :fileContent="message.fileContent"
            :fromSelfSide="fromSelfSide" :chatWidth="chatWidth" />
          <ChatMessageText v-else :class="{ 'highlighted-message': isHighlighted() }" 
            :textContent="message.textContent"
            :fromSelfSide="fromSelfSide" :chatWidth="chatWidth" />
          <div class="message-actions" ref="actions" v-if="hasActions">
            <img src="/icons/dots.png" alt="Actions" class="actions-icon" @click.prevent="openActionsPopup">
            <transition name="modal-pop" mode="out-in">
              <div class="actions-popup" ref="popup" v-if="isPopupOpen">
                <span @click.prevent="onForwardMessage">Forward Message</span>
              </div>
            </transition>
          </div>
        </div>
        <div class="message-footer" v-if="isLastOfKind">
          <img src="/icons/email.png" alt="Email Icon" class="lock-icon" v-tooltip="'Secure Email'"
            v-if="message.messageType == 'Email'">
          <img src="/icons/lock.png" alt="Lock Icon" class="lock-icon" v-tooltip="'Secure Message'"
            v-else-if="message.isSecure">
          <img src="/icons/sms.png" alt="SMS Icon" class="lock-icon" v-tooltip="'Insecure SMS'" v-else>
          <span>{{ message.sender.name }} • {{ getMessageTime() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMessageText from './ChatMessageText.vue'
import ChatMessageFile from './ChatMessageFile.vue'
import ChatMessageRef from './ChatMessageRef.vue'

export default {
  name: 'ChatMessageRow',
  props: {
    message: Object,
    fromSelfSide: Boolean,
    isFirstOfDay: Boolean,
    isLastOfKind: Boolean,
    isFirstOfKind: Boolean,
    hideSelfPicture: Boolean,
    profilePicture: String,
    hasActions: Boolean,
    chatWidth: Number,
    msgRef: String
  },
  data() {
    return {
      isPopupOpen: false
    }
  },
  mounted() {
    window.addEventListener('click', this.closeActionsPopup);
  },
  unmounted() {
    window.removeEventListener('click', this.closeActionsPopup);
  },
  methods: {
    getMessageDate() {
      const date = new Date(this.message.dateCreated);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

      // Check if the date is today
      if (date.toLocaleDateString() === today.toLocaleDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday";
      }

      // Check if the date is within the last week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      if (today - date < 7 * oneDay) {
        return dayOfWeek;
      }

      // If more than a week ago, return MM/DD/YYYY format
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
    isHighlighted() {
      return this.message.id === this.msgRef;
    },
    getMessageTime() {
      const date = new Date(this.message.dateCreated);

      const time = date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true // Use true for AM/PM format, false for 24-hour format
      });

      return time;
    },
    shouldShowPicture() {

      if (this.hideSelfPicture && this.fromSelfSide) {
        return false;
      }

      return true;
    },
    openActionsPopup() {
      this.isPopupOpen = true;

      this.$nextTick(() => {
        const popupRef = this.$refs.popup;
        if (!popupRef) return;
        const rect = popupRef.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const x = rect.left + scrollLeft;
        console.log(x);

        if (this.fromSelfSide) {
          const distFromLeft = x;
          if (distFromLeft < 15) {
            popupRef.style.right = (distFromLeft - 15) + 'px';
          }
        } else {
          const distFromRight = window.innerWidth - x - rect.width;
          if (distFromRight < 15) {
            popupRef.style.left = (distFromRight - 15) + 'px';
          }
        }
      })
    },
    closeActionsPopup(event) {
      const actionsRef = this.$refs.actions;
      if (!actionsRef) return;
      if (actionsRef.contains(event.target)) return;

      this.isPopupOpen = false;
    },
    onForwardMessage() {
      this.isPopupOpen = false;
      this.$emit('onForwardMessage');
    },
  },
  components: { ChatMessageText, ChatMessageFile, ChatMessageRef },
  emits: ['onForwardMessage']
}

</script>

<style scoped>
.chat-message {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}

.message-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.author-column {
  width: 48px;
}

.chat-message[data-from-self-side="true"] .message-content {
  flex-direction: row-reverse;
}

.chat-message[data-from-self-side="true"] .message-column {
  align-items: flex-end;
}

.message-footer {
  font-size: 14px;
  text-wrap: nowrap;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  opacity: 0.75;
}

.chat-message[data-from-self-side="true"] .message-footer {
  flex-direction: row-reverse;
}

.author-picture {
  width: 100%;
  border-radius: 50%;
  display: block;
}

.message-date-strip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 50px;
}

.message-date {
  font-size: 14px;
  padding: 10px;
  background: #F5F5F5;
  color: #999;
}

.lock-icon {
  height: 18px;
  display: block;
}

.actions-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actions-icon {
  height: 24px;
  display: block;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity ease-in-out 0.1s;
}

.actions-icon:hover {
  opacity: 1;
}

.chat-message:not(:hover) .actions-icon {
  opacity: 0;
}

.message-body {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-message[data-from-self-side="true"] .message-body {
  flex-direction: row-reverse;
}

.message-actions {
  position: relative;
}

.highlighted-message {
  background-color: yellow;
}

.actions-popup {
  position: absolute;
  top: -50%;
  left: 0;
  background: #FFF;
  box-shadow: 0px 0px 12px #00000022;
  border-radius: 5px;
  overflow: hidden;
}

.actions-popup span {
  display: block;
  text-wrap: nowrap;
  padding: 15px;
}

.actions-popup span:hover {
  background: var(--color-fill-med);
  cursor: pointer;
}

.chat-message[data-from-self-side="true"] .actions-popup {
  right: 0;
  left: unset;
}
</style>