<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Add New Patient</h1>
      <p class="subtitle">Please fill out the patient's basic information to create a new record.</p>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptCreatePatient">
        <div class="form-column">
          <div class="form-group">
            <label class="form-label">First Name</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="firstName">
          </div>
          <div class="form-group">
            <label class="form-label">Middle Name</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="middleName">
          </div>
          <div class="form-group">
            <label class="form-label">Last Name</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="lastName">
          </div>
        </div>
        <div class="form-column">
          <div class="form-group">
            <label class="form-label">Date of Birth</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="dateOfBirth" @input="formatDateOfBirth">
          </div>
          <div class="form-group">
            <label class="form-label">Phone Number</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="phoneNumber">
          </div>
          <div class="form-group">
            <label class="form-label">Email Address</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="emailAddress">
          </div>
        </div>
        <input type="submit" value="" hidden>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptCreatePatient">Create Patient</button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { createPatient } from '@/api';

export default {
  name: "CreatePatientModal",
  data() {
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      emailAddress: '',
      loading: false,
      error: ''
    }
  },
  methods: {
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    },
    async attemptCreatePatient() {
      if (this.loading) {
        return;
      }

      try {
        this.loading = true;
        this.error = '';
        const patient = await createPatient(this.firstName, this.middleName, this.lastName, this.dateOfBirth, this.phoneNumber, this.emailAddress);
        this.$emit('onPatientCreated', patient);
      } catch (err) {
        console.error('Failed to create patient');
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['openModal', 'closeModal', 'onPatientCreated']
};
</script>

<style scoped>
.modal-card {
  max-width: 640px;
}

.form {
  flex-direction: row;
}

@media screen and (max-width: 1000px) {
  .form {
    flex-direction: column;
  }
}
</style>