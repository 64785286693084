<template>
  <div class="char-code">
    <input type="text" v-for="char of chars" v-model="char.value" class="form-control" ref="chars" :key="char.index"
      @input="onInput(char.index, $event)" @keydown="onKeyDown(char.index, $event)" autocomplete="off"
      data-lpignore="true" data-form-type="other" />
  </div>
</template>

<script>
export default {
  name: "CharCodeInput",
  props: {
    length: Number,
    initialChars: String
  },
  data() {
    return {
      chars: [],
    };
  },
  mounted() {
    const initVal = this.cleanValue(this.initialChars);

    for (let i = 0; i < this.length; i++) {
      let val = '';
      if (initVal && initVal.length > i) {
        val = initVal.charAt(i);
      }

      this.chars.push({ index: i, value: val });
    }
  },
  methods: {
    cleanValue(value) {
      value = value.toUpperCase();
      value = value.replace(/[^A-Z]/g, '');
      return value;
    },
    setChar(index, value) {
      if (index >= this.chars.length) return;
      this.chars[index].value = value;
    },
    focusChar(index) {
      const charElements = this.$refs.chars;
      charElements[Math.min(index, charElements.length - 1)].focus();
    },
    onInput(index) {
      const value = this.chars[index].value;
      const cleanValue = this.cleanValue(value);
      const valueLength = cleanValue.length;

      // Apply value starting at current digit
      this.setChar(index, '');
      for (let i = 0; i < valueLength; i++) {
        this.setChar(index + i, cleanValue[i]);
      }

      // Focus next digit
      this.focusChar(index + valueLength);
      this.updateValue();
    },
    onKeyDown(index, event) {
      const value = this.chars[index].value;
      const valueLength = value.length;

      if (event.code >= 'KeyA' && event.code <= 'KeyZ') {
        this.setChar(index, '');
      }

      if (event.code == 'Backspace') {
        if (valueLength > 0) {
          this.setChar(index, '');
          this.updateValue();
        } else if (index > 0) {
          this.setChar(index - 1, '');
          this.focusChar(index - 1);
          this.updateValue();
        }
      }
    },
    updateValue() {
      const value = this.chars.map(char => char.value).join('')
      const cleanValue = this.cleanValue(value);
      this.$emit('updateValue', cleanValue)
    },
  },
  emits: ['updateValue']
};
</script>

<style scoped>
.char-code {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.char-code .form-control {
  text-align: center;
  width: 1px;
  flex: 1;
  font-weight: normal;
  font-size: 32px;
}

@media screen and (max-width: 1000px) {

  .char-code {
    gap: 5px;
  }

  .char-code .form-control {
    font-size: 20px;
  }
}
</style>