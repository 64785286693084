<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Schedule Meeting</h1>
      <p class="subtitle">Please select a time for the call and add any useful information/context.</p>
    </div>
    <div class="modal-card-body">
      <div class="row icons-row">
        <div class="patient-icons">
          <img class="patient-picture" :src="getPatientProfilePicture()">
        </div>
        <span>+</span>
        <div class="user-icons">
          <img class="user-picture" :src="getProfilePicture(user)" :title="user.name" :alt="user.name"
            v-for="user of users" :key="user.id">
        </div>
      </div>
      <div class="row desc-row">
        <div class="column" style="flex: 1">
          <div class="form-group">
            <label for="" class="form-label">Meeting Name</label>
            <input type="text" class="form-control" v-model="name">
          </div>
          <div class="form-group" style="flex: 1">
            <label for="" class="form-label">Meeting Description</label>
            <textarea class="form-control" v-model="description"></textarea>
          </div>
        </div>
        <div class="column">
          <div class="form-group">
            <label for="" class="form-label">Meeting Time</label>
            <DatePicker style="width: 100%" mode="dateTime" :min-date="new Date()"
              :rules="{ minutes: { interval: 5 }, }" v-model="time" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="createMeeting">Create Meeting</button>
    </div>
  </div>
</template>

<script>
import { createMeeting } from '@/api'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css';

export default {
  name: "CreateMeetingModal",
  props: {
    users: Array,
    patient: Object
  },
  data() {
    return {
      name: '',
      description: '',
      time: ''
    }
  },
  methods: {
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getPatientProfilePicture() {
      if (!this.patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${this.patient.profilePictureKey}`;
    },
    async createMeeting() {
      const userIds = this.users.map(user => user.id);
      const patientId = this.patient.id;
      const meeting = await createMeeting(patientId, userIds, this.time, this.name, this.description);
      console.info('Created a meeting with users and patient', meeting)
      this.$emit('onMeetingCreated', meeting);
    }
  },
  components: { DatePicker },
  emits: ['openModal', 'onMeetingCreated']
};
</script>

<style scoped>
.modal-card {
  max-width: 750px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.user-icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.user-picture,
.patient-picture {
  width: 40px;
  height: 40px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.modal-card-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.row {
  display: flex;
  gap: 25px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

textarea {
  resize: none;
  height: 100%;
}

.icons-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .desc-row {
    flex-direction: column;
  }
}
</style>