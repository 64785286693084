<template>
  <div class="chat-message-text" :data-from-self-side="fromSelfSide" :style="{ maxWidth: getMaxWidth() + 'px' }">
    <span class="message-text">
      <template v-for="(part, idx) of getTextParts(textContent)">
        <template v-if="part.type == 'link'">
          <a :href="part.content" target="_blank" :key="idx" @click.stop="">{{part.content}}</a>
        </template>
        <template v-else>
          <span :key="idx">{{part.content}}</span>
        </template>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ChatMessageText',
  props: {
    textContent: String,
    fromSelfSide: Boolean,
    chatWidth: Number
  },
  methods: {
    getMaxWidth() {
      return Math.min(600, this.chatWidth * 0.66);
    },
    getTextParts(input) {
      if (!input) return [];

      const urlPattern = /https?:\/\/[^\s]+[^\s.,!?]/g;
      const result = [];
      let match;
      let lastIndex = 0;

      while ((match = urlPattern.exec(input)) !== null) {
        // Add text part before the link
        if (match.index > lastIndex) {
          result.push({
            type: "text",
            content: input.substring(lastIndex, match.index)
          });
        }

        // Extract the matched URL
        let url = match[0];

        // Check for trailing punctuation and remove it
        while (/[.,!?]$/.test(url)) {
          url = url.slice(0, -1);
        }

        // Add the link part
        result.push({
          type: "link",
          content: url
        });

        // Update lastIndex to the end of the current match
        lastIndex = match.index + match[0].length;
      }

      // Add any remaining text after the last link
      if (lastIndex < input.length) {
        result.push({
          type: "text",
          content: input.substring(lastIndex)
        });
      }

      return result;
    }
  }
}
</script>

<style scoped>
.chat-message-text {
  padding: 12px;
  border-radius: 18px;
  background: #EEE;
  min-height: 48px;
  line-height: 24px;
}

.chat-message-text[data-from-self-side="true"] {
  border-bottom-right-radius: 5px;
  background: var(--color-primary);
  color: #FFF;
}

.chat-message-text[data-from-self-side="false"] {
  border-bottom-left-radius: 5px;
}

.chat-message-text[data-from-self-side="true"] a {
  color: #dcc5ff;
}

.chat-message-text[data-from-self-side="false"] a {
  color: #0059ff;
}

span {
  white-space: pre;
  text-wrap: wrap;
  max-width: 100%;
}

a {
  word-break: break-all;
}
</style>