<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Terminate Account</h1>
      <p class="subtitle">Are you sure you want to terminate the customer <u>{{account.name}}</u>? This customer will no longer be able to use DashQuill.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="attemptRemoveUser">Delete</button>
      <span class="error" v-if="error">{{error}}</span>
    </div>
  </div>
</template>

<script>
import {removeAccount} from '@/api'

export default {
  name: "RemoveUserModal",
  props: {
    account: Object
  },
  data() {
    return {
      pending: false,
      error: ""
    }
  },
  methods: {
    async attemptRemoveAccount() {
      try {
        this.pending = true;
        const accountId = this.account.id;
        await removeAccount(accountId);
        this.$emit('onAccountRemoved', accountId);
      } catch (err) {
        this.error = err.message;
        console.error(err);
      } finally {
        this.pending = false;
      }
    },
  },
  emits: ['onAccountRemoved']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}

</style>