<template>
  <div id="full">
    <transition name="modal-appear" mode="out-in">
      <FormFiller v-if="formInfo" :formInfo="formInfo" />
    </transition>
  </div>
</template>

<script>
import * as formsApi from '../../api/forms'
import FormFiller from '../../components/forms/FormFiller.vue';

export default {
  name: 'PatientFormsView',
  data() {
    return {
      formInfo: null,
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.formId) {
      this.attemptFetchFormInfo(query.formId);
    }
  },
  methods: {
    openModal(id, data) {
      console.info('opening modal', id);
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    async attemptFetchFormInfo(id) {
      try {
        const formInfo = await formsApi.fetchFormInfo(id);
        this.formInfo = formInfo;
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: {
    FormFiller
  }
}
</script>

<style scoped>
#full {
  overflow-y: auto;
  padding-right: 0px;
  padding-top: calc(50px + env(safe-area-inset-top));
  padding-bottom: 50px;
}

@media screen and (max-width: 1000px) {
  #full {
    padding-top: calc(10px + env(safe-area-inset-top));
  }
}

</style>