<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Upload Files</h1>
      <p class="subtitle">Please drag and drop a file that you want to send to this chat.</p>
    </div>
    <div class="modal-card-body">
      <div class="file-drop-zone form-control" @click.prevent.stop="selectFile" @dragover.prevent.stop="onDragOver"
        @drop.prevent.stop="onDrop">
        <img src="/icons/upload.png" alt="Upload Icon" class="upload-icon" v-if="!file">
        <div class="file-preview-image" :style="{ backgroundImage: `url(${fileSrc})` }" v-else-if="fileSrc"></div>
        <span class="file-preview-other" v-else><img src="/icons/file.png" alt="File Icon" class="icon-sm">{{ file.name
          }}</span>
      </div>
    </div>
    <div class="modal-card-footer">
      <div class="button-list">
        <button class="button primary" @click.prevent="attemptSendFile('SMS')">
          <img v-if="pendingSms" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Send via SMS</span>
        </button>
        <button class="button primary" @click.prevent="attemptSendFile('Email')">
          <img v-if="pendingEmail" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Send via Email</span>
        </button>
      </div>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFilesModal",
  props: {
    chatInfo: Object,
    fromPatient: Boolean
  },
  data() {
    return {
      file: null,
      fileSrc: '',
      pendingSms: false,
      pendingEmail: false,
      error: ''
    }
  },
  methods: {
    selectFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.click();

      input.onchange = (event) => {
        const file = event.target.files[0];
        this.file = file;
        this.fileSrc = '';

        // If it's an image, show preview
        if (file.type.startsWith('image/')) {
          const fr = new FileReader();
          fr.onloadend = () => {
            this.fileSrc = fr.result;
          }
          fr.readAsDataURL(this.file);
        }
      }
    },
    onDragOver(event) {
      event.dataTransfer.dropEffect = 'copy';
    },
    onDrop(event) {
      const files = event.dataTransfer.files;
      this.file = files[0];
    },
    attemptSendFile(method) {
      if (this.pendingSms || this.pendingEmail) {
        return;
      }
      this.error = "";
      
      if (method == 'SMS') {
        this.pendingSms = true;
        this.$emit('onFileUploaded', this.file, 'SMS', this.handleUploadError);
      } else {
        this.pendingEmail = true;
        this.$emit('onFileUploaded', this.file, 'Email', this.handleUploadError);
      }
      
    },
    handleUploadError(err) {
      if (err) {
        this.error = err.message;
      }

      this.pendingSms = false;
      this.pendingEmail = false;
    },
  },
  components: {},
  emits: ['openModal', 'closeModal', 'onFileUploaded']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.file-drop-zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  width: 50px;
  height: 50px;
  display: block;
  opacity: 0.1;
}

.file-preview-image {
  display: block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.file-preview-other {
  padding: 10px;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-border-med);
}

.icon-sm {
  height: 24px;
  display: block;
}

.button-list {
  display: flex;
  gap: 10px;
}
</style>