<template>
  <div class="chat-message-file" :data-from-self-side="fromSelfSide" :data-is-image="isImage()"
    :style="{ width: getMaxWidth() + 'px' }" @click.prevent="attemptDownloadFile">
    <div class="file-header">
      <img src="/icons/download.png" alt="Download icon" class="download-icon">
      <span class="file-name">{{ fileContent.name }}</span>
      <span class="file-size">({{ getFileSize() }})</span>
    </div>
    <div class="file-preview" :style="{ backgroundImage: `url(${src})` }" v-if="isImage()"></div>
  </div>
</template>

<script>
import { downloadPatientChatFile } from '@/api';
import { isMobile } from '../mobile/MobileUtils.js';
// import { Filesystem, FilesystemDirectory, FilesystemEncoding } from '@capacitor/filesystem'

export default {
  name: 'ChatMessageText',
  props: {
    fileContent: String,
    fromSelfSide: Boolean,
    chatWidth: Number
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
    this.loadImage();
  },
  methods: {
    isImage() {
      return this.fileContent.mimetype.startsWith('image/')
    },
    getFileSize() {
      const bytes = this.fileContent.size;

      if (bytes < 1024) {
        return `${bytes} bytes`
      }

      const kb = Math.floor(bytes / 1024);
      if (kb < 1024) {
        return `${kb} kb`
      }

      const mb = Math.floor(kb / 1024);
      return `${mb} mb`;
    },
    async blobToBase64(blob) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    getMaxWidth() {
      return Math.min(400, this.chatWidth * 0.66);
    },
    async loadImage() {
      const fc = this.fileContent;
      let resObj;

      const isDevMobile = await isMobile();
      if (isDevMobile) {
        const { mimeType, base64 } = await downloadPatientChatFile(fc.id, true); // base64
        resObj = base64;
        this.src = `data:${mimeType};base64,${resObj}`;
        return;
      }

      resObj = await downloadPatientChatFile(fc.id, false);
      this.src = resObj;
      this.src = URL.createObjectURL(resObj);
    },
    async attemptDownloadFile() {
      const fc = this.fileContent;

      // const isDevIOS = await isMobile();
      // if (isDevIOS) {
      //   const { fileName, mimeType, base64 } = await downloadPatientChatFile(fc.id, true); // base64
      //   const base64StringFull = `data:${mimeType};base64,${base64}`;
      //   // Write the file to the device
      //   console.log('am i inside');
      //   const result = await Filesystem.writeFile({
      //     path: fileName,
      //     data: base64StringFull,
      //     directory: FilesystemDirectory.Documents,
      //     encoding: FilesystemEncoding.UTF8
      //   });
      //   console.log('result', result);
      //   return;
      // }

      const blob = await downloadPatientChatFile(fc.id);

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fc.name;
      link.click();
      window.URL.revokeObjectURL(blobUrl);
    }
  }
}
</script>

<style scoped>
.chat-message-file {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 18px;
  cursor: pointer;
}

.file-header {
  padding: 12px;
  background: #EEE;
  min-height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-message-file[data-from-self-side="true"] .file-header {
  background: var(--color-primary);
  color: #FFF;
}

.chat-message-file[data-is-image="true"] .file-header {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.chat-message-file[data-from-self-side="false"] {
  border-bottom-left-radius: 5px;
}

.chat-message-file[data-from-self-side="true"] {
  border-bottom-right-radius: 5px;
}

.file-preview {
  width: 100%;
  height: 200px;
  background: #F5F5F5;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0px 0px 16px #00000022;
}

.download-icon {
  display: block;
  height: 24px;
}

.chat-message-file[data-from-self-side="false"] .download-icon {
  filter: invert(1);
}

.file-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-size {
  opacity: 0.5;
}
</style>