<template>
  <div id="wrapper">
    <div id="full">
      <transition name="modal-appear" mode="out-in">
        <div class="modal-card" style="max-width: 450px" v-if="firstPage">
          <div class="modal-card-header">
            <h1 class="title">Welcome back!</h1>
            <p class="subtitle">Please sign into your DashQuill account using your email and password.</p>
          </div>
          <div class="modal-card-body">
            <form class="form" @submit.prevent="requestTwoFactor">
              <div class="form-group">
                <label for="email" class="form-label">Email Address</label>
                <input id="email" type="text" class="form-control" v-model="email">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" v-model="password">
              </div>
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="requestTwoFactor">
              <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
              <span v-else>Login</span>
            </button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
        <div class="modal-card" style="max-width: 500px" v-else>
          <div class="modal-card-header">
            <h1 class="title">Check your email</h1>
            <p class="subtitle"> We have sent a two factor authentication code to <u>{{ email }}</u>. It will expire in
              5 minutes.</p>
          </div>
          <div class="modal-card-body">
            <form class="form" @submit.prevent="attemptLogin">
              <AuthCodeInput :length="6" @updateValue="updateTwoFactor" />
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="attemptLogin">
              <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
              <span v-else>Login</span>
            </button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { userLogin, userTwoFactor } from '@/api'
import AuthCodeInput from '../components/AuthCodeInput.vue'
import { userCheckAuth } from '../api';
import { isMobile } from '../mobile/MobileUtils.js';
import { attemptToLink } from '../mobile/PushNotification.js';

const EMAIL_REGEX = /^(.+)@(.+)\.(.+)$/

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      twoFactor: '',
      firstPage: true,
      error: '',
      pending: false
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        window.location.replace('/apps');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          console.info('Login checker - OK');
        }
      })
  },
  methods: {
    async requestTwoFactor() {
      if (this.pending) {
        return;
      }

      // Test email
      if (!EMAIL_REGEX.test(this.email)) {
        this.error = 'Email must have format like name@email.com';
        return;
      }

      try {
        this.error = '';
        this.pending = true;

        // fake delay
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        await userTwoFactor(this.email, this.password);
        this.firstPage = false;
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    async attemptLogin() {
      if (this.pending) {
        return;
      }

      try {
        this.error = '';
        this.pending = true;

        // fake delay
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        await userLogin(this.email, this.password, this.twoFactor);
        console.log("Calling Register Notifications")
        const isDevMobile = await isMobile();
        if (isDevMobile) {
          await attemptToLink();
        }
        
        const query = this.$route.query;
        if (query.ref) {
          window.location.replace(query.ref);
        } else {
          this.$router.push('/apps');
        }

      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    updateTwoFactor(value) {
      this.twoFactor = value;

      if (value.length == 6) {
        this.attemptLogin();
      }
    }
  },
  components: { AuthCodeInput }
}
</script>

<style scoped>
#wrapper {
  background-image: radial-gradient(30% 60%, #ffffff, #e1e6ea);
}

#full {
  background: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e1e6ea' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  padding: 10px;
}

.error {
  display: block;
  margin: auto;
  text-align: center;
  color: red;
  margin-top: 10px;
}
</style>