import { Device } from '@capacitor/device';

export async function getDevicePlatform() {
    const info = await Device.getInfo();
    return info.platform; // 'ios', 'android', 'web', etc.
}

export async function isAndroid() {
    const platform = await getDevicePlatform();
    return platform === 'android';
}

export async function isIOS() {
    const platform = await getDevicePlatform();
    return platform === 'ios';
}

export async function isMobile() {
    const isDevAndroid = await isAndroid();
    const isDevIOS = await isIOS();
    return isDevAndroid || isDevIOS;
}