// USER

export async function userNotifications() {
  const resJson = await postJson('/core/user/notifications');
  if (!resJson.notifications) throw Error('Malformed response from backend');
  return resJson;
}

export async function userMarkNotificationsRead() {
  await postJson('/core/user/mark-notifications-read');
}

// Firebase

export async function unlinkFirebaseNotifications(deviceId) {
  const resJson = await postJson('/core/firebase/unlink', { deviceId });
  if (!resJson) throw Error('Malformed response from backend');
  return resJson;
}

// UNLABELED

export async function userCheckAuth() {
  await postJson('/core/user/check-auth');
}

export async function createAccount(name, practiceType, practiceEMR, masterEmail, masterPassword) {
  const resJson = await postJson('/core/account/create', { name, practiceType, practiceEMR, masterEmail, masterPassword });
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function fetchAccountInfo() {
  const resJson = await postJson('/core/account/info');
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function editAdminMessagingSettings(accountId, isEnabled, smsSenderNumber, optInMessage, senderEmail) {
  const resJson = await postJson('/messaging/settings/set-admin', { accountId, isEnabled, smsSenderNumber, optInMessage, senderEmail });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function editAdminMeetingsSettings(accountId, isEnabled, smsSenderNumber, optInMessage) {
  await postJson('/meets/settings/set-admin', { accountId, isEnabled, smsSenderNumber, optInMessage });
}

export async function getAdminMessagingSettings(accountId) {
  const resJson = await postJson('/messaging/settings/get-admin', { accountId });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function userTwoFactor(email, password) {
  await postJson('/core/user/two-factor', { email, password });
}


export async function logout() {
  await postJson('/core/user/logout');
}

export async function removeAccount(accountId) {
  await postJson('/core/account/remove', { accountId });
}

export async function createUser(invitationURL, password) {
  await postJson('/core/user/create', { invitationURL, password });
}

export async function removeUser(userId) {
  await postJson('/core/user/remove', { userId });
}

export async function editUser(userId, name, practiceRole) {
  const resJson = await postJson('/core/user/edit', { userId, name, practiceRole });
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function userLogin(email, password, twoFactor) {
  await postJson('/core/user/login', { email, password, twoFactor });
}

export async function userInfo() {
  const resJson = await postJson('/core/user/info');
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function fetchAllUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function fetchPatient(patientId) {
  const resJson = await postJson('/core/patient/info', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function createPatient(firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId) {
  const patientData = {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    emrIntegrationId
  };

  if (mobilePhoneNumber) {
    patientData.mobilePhoneNumber = mobilePhoneNumber;
  }

  if (emailAddress) {
    patientData.emailAddress = emailAddress;
  }

  const resJson = await postJson('/core/patient/create', patientData);
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}


export async function archivePatient(patientId) {
  return await postJson('/core/patient/archive', { patientId });
}

export async function toggleOptIn(patientId, optIn) {
  const resJson = await postJson('/core/patient/opt-in', { patientId, optIn });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function updatePatient(patientId, fields) {
  const resJson = await postJson('/core/patient/update', { patientId, ...fields });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function searchPatients(query, afterPatientId) {
  const resJson = await postJson('/core/patient/search', { query, afterPatientId });
  if (!resJson.patients) throw Error('Malformed response from backend');
  return resJson;
}

export async function listInvitations() {
  const resJson = await postJson('/core/userinvitation/list');
  if (!resJson.invitations) throw Error('Malformed response from backend');
  return resJson.invitations;
}

export async function inviteUser(name, email, practiceRole) {
  const resJson = await postJson('/core/userinvitation/create', { name, email, practiceRole });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function revokeInvite(invitationId) {
  await postJson('/core/userinvitation/revoke', { invitationId });
}

export async function inviteInfo(privateURL) {
  const resJson = await postJson('/core/userinvitation/info', { privateURL });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function getMessagingSettings() {
  const resJson = await postJson('/messaging/settings/get');
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function setMessagingSettings(settings) {
  const resJson = await postJson('/messaging/settings/set', { ...settings });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function getUserMessagingSettings() {
  const resJson = await postJson('/messaging/usersettings/get');
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function setUserMessagingSettings(settings) {
  const resJson = await postJson('/messaging/usersettings/set', { ...settings });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function emrToPatient(emrIntegrationId) {
  const resJson = await postJson('/core/patient/emr-to-patient', { emrIntegrationId });
  return resJson;
}

// export async function uploadPatientProfilePicture(patientId, file) {
//   const formBody = new FormData();
//   formBody.set('file', file);
//   formBody.set('patientId', patientId);

//   const resJson = await postForm('/core/patient/upload-profile-picture', formBody);
//   if (!resJson.key) throw Error('Malformed response from backend');
//   return resJson.key;
// }

export async function uploadUserProfilePicture(userId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('userId', userId);

  const resJson = await postForm('/core/user/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

export async function listAccounts() {
  const resJson = await postJson('/core/account/list');
  if (!resJson.accounts) throw Error('Malformed response from backend');
  return resJson.accounts;
}

// ADMIN

export async function adminLogin(accountId) {
  await postJson('/core/account/admin-login', { accountId });
}

const fetchData = async (url, options) => {
  const response = await fetch(url, options);
  return response;
};
async function postForm(url, formData) {
  const res = await fetchData(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

async function postJson(url, body) {
  const res = await fetchData(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}


/** Messaging */

// export async function userCheckAuth() {
//   await postJson('/core/user/check-auth');
// }

// export async function userInfo() {
//   const resJson = await postJson('/core/user/info');
//   if (!resJson.user) throw Error('Malformed response from backend');
//   return resJson.user;
// }

export async function listUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function createGroupChat(name, users) {
  const resJson = await postJson('/messaging/groupchat/create', { name, users });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listGroupChats(afterChatId) {
  const resJson = await postJson('/messaging/groupchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchGroupChatInfo(chatId) {
  const resJson = await postJson('/messaging/groupchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

// export async function logout() {
//   await postJson('/core/user/logout');
// }

export async function listPatients(pageNum, pageSize) {
  const resJson = await postJson('/core/patient/list', { pageNum, pageSize });
  return {
    patients: resJson.patients,
    currentPage: resJson.currentPage,
    totalPages: resJson.totalPages
  }
}

// export async function createPatient(firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId) {
//   const resJson = await postJson('/core/patient/create', {
//     firstName,
//     middleName,
//     lastName,
//     dateOfBirth,
//     mobilePhoneNumber,
//     emailAddress,
//     emrIntegrationId
//   });
//   if (!resJson.patient) throw Error('Malformed response from backend');
//   return resJson.patient;
// }

// export async function fetchPatient(patientId) {
//   const resJson = await postJson('/core/patient/info', { patientId });
//   if (!resJson.patient) throw Error('Malformed response from backend');
//   return resJson.patient;
// }

export async function urlToPatientId(privateURL) {
  const resJson = await postJson('/messaging/patientchat/url-to-patient-id', { privateURL });
  if (!resJson.patientId) throw Error('Malformed response from backend');
  return resJson.patientId;
}

export async function patientLogin(patientId, dateOfBirth) {
  await postJson('/core/patient/login', { patientId, dateOfBirth });
}

// export async function searchPatients(query, afterPatientId) {
//   const resJson = await postJson('/core/patient/search', { query, afterPatientId });
//   if (!resJson.patients) throw Error('Malformed response from backend');
//   return resJson;
// }

// Chats

export async function createPatientChat(patientId) {
  const resJson = await postJson('/messaging/patientchat/create', { patientId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listLatestPatientChats(afterChatId) {
  const resJson = await postJson('/messaging/patientchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchPatientChatInfo(chatId) {
  const resJson = await postJson('/messaging/patientchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function fetchPatientChatInfoForPatient(patientId) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-patient', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ patientId })
  });

  // If it's a 404, patient does not have chat
  if (res.status == 404) {
    return undefined;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function fetchPatientChatInfoForPrivateUrl(privateURL) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-url', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ privateURL })
  });

  // If it's a 401, patient is not authenticated right now
  if (res.status == 401) {
    return undefined;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function markChatResolved(chatId, isResolved) {
  const resJson = await postJson('/messaging/patientchat/mark-resolved', { chatId, isResolved });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

// Files

export async function sendPatientFileMessage(file) {
  const formBody = new FormData();
  formBody.set('file', file);

  const resJson = await postForm('/messagefile/send-patient-upload', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

// Templates
export async function fetchTemplates() {
  const resJson = await postJson('/messaging/template/list');
  if (!resJson.templates) throw Error('Malformed response from backend');
  return resJson.templates;
}

export async function createTemplate(name, text) {
  const resJson = await postJson('/messaging/template/create', { name, text });
  if (!resJson.template) throw Error('Malformed response from backend');
  return resJson.template;
}

export async function deleteTemplate(templateId) {
  await postJson('/messaging/template/remove', { templateId });
}

export async function editTemplate(templateId, name, text) {
  const resJson = await postJson('/messaging/template/edit', { templateId, name, text });
  if (!resJson.template) throw Error('Malformed response from backend');
  return resJson.template;
}

// export async function getMessagingSettings() {
//   const resJson = await postJson('/messaging/settings/get');
//   if (!resJson.settings) throw Error('Malformed response from backend');
//   return resJson.settings;
// }

export async function downloadPatientProfilePicture(patientId) {
  const res = await fetch(process.env.VUE_APP_API + '/core/patient/download-profile-picture', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ patientId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

export async function uploadPatientProfilePicture(patientId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('patientId', patientId);

  const resJson = await postForm('/core/patient/upload-profile-picture', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function downloadUserProfilePicture(userId) {
  const res = await fetch(process.env.VUE_APP_API + '/core/user/download-profile-picture', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

export async function useOneTimeCode(oneTimeCode) {
  await postJson('/core/user/use-otc', { oneTimeCode });
}

// PATIENT CHAT MESSAGE

export async function patientChatListMessages(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/patientchatmessage/list', { chatId, afterMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function patientChatSendUserText(chatId, text, isSecure, messageType) {
  const resJson = await postJson('/messaging/patientchatmessage/send-user-text', { chatId, text, isSecure, messageType });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientText(chatId, text) {
  const resJson = await postJson('/messaging/patientchatmessage/send-patient-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendUserFile(chatId, file, messageType) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);
  formBody.set('messageType', messageType)

  const resJson = await postForm('/messaging/patientchatmessage/send-user-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientFile(chatId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);

  const resJson = await postForm('/messaging/patientchatmessage/send-patient-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/patientchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function downloadPatientChatFile(fileId, b64) {
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchatmessage/download-file', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId: fileId, b64 }),
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  if (b64) {
    const json = await res.json();
    return json; // also have json.fileName and json.mimeType
  }

  const blob = await res.blob();
  return blob;
}

// GROUP CHAT

export async function groupChatAddMembers(chatId, users) {
  const resJson = await postJson('/messaging/groupchat/add-members', { chatId, users });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function groupChatLeaveChat(chatId) {
  await postJson('/messaging/groupchat/leave-chat', { chatId });
}

// GROUP CHAT MESSAGE

export async function groupChatListMessages(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/groupchatmessage/list', { chatId, afterMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function groupChatSendUserText(chatId, text) {
  const resJson = await postJson('/messaging/groupchatmessage/send-user-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatSendUserFile(chatId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);

  const resJson = await postForm('/messaging/groupchatmessage/send-user-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatForwardMessage(messageId, groupChatId, isPatientChat) {
  const resJson = await postJson('/messaging/groupchatmessage/forward-message', { messageId, groupChatId, isPatientChat });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/groupchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupPatientChatFile(fileId) {
  const res = await fetch(process.env.VUE_APP_API + '/messaging/groupchatmessage/download-file', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId: fileId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

export async function createMeeting(patient, expectedUsers, meetingTime, title, description) {
  const resJson = await postJson('/meets/patientmeeting/create', { patient, expectedUsers, meetingTime, title, description });
  if (!resJson.meeting) throw Error('Malformed response from backend');
  return resJson.meeting;
}

export async function listMeetings(localTimeZone) {
  const resJson = await postJson('/meets/patientmeeting/list-all', { localTimeZone });
  if (!resJson.meetings) throw Error('Malformed response from backend');
  return resJson.meetings;
}

export async function meetJoinMeeting(accountId, roomName) {
  const resJson = await postJson('/meets/patientmeeting/join', { accountId, roomName });
  if (!resJson.meetingLink) throw Error('Malformed response from backend');
  return resJson.meetingLink;
}

export async function sendMeetingReminder(type, meetingId, localTimeZone) {
  const resJson = await postJson('/meets/patientmeeting/send-reminder', { type, meetingId, localTimeZone });
  if (!resJson.reminder) throw Error('Malformed response from backend');
  return resJson.reminder;
}


export async function meetJoinMeetingProtected(roomName) {
  const resJson = await postJson('/meets/patientmeeting/join-protected', { roomName });
  if (!resJson.meetingLink) throw Error('Malformed response from backend');
  return resJson.meetingLink;
}

export async function meetsInfo(accountId, roomName) {
  const resJson = await postJson('/meets/patientmeeting/info', { accountId, roomName });
  if (!resJson.meeting) throw Error('Malformed response from backend');
  return resJson.meeting;
}

export async function listUnverifiedChats(afterChatId) {
  const resJson = await postJson('/messaging/unverifiedchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function unverifiedChatFetchInfo(chatId) {
  const resJson = await postJson('/messaging/unverifiedchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function unverifiedChatConvert(unverifiedChatId, firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress) {
  const formBody = {
    unverifiedChatId,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    mobilePhoneNumber
  };

  if (emailAddress) {
    formBody.emailAddress = emailAddress;
  }
  
  const resJson = await postJson('/messaging/unverifiedchatmessage/convert', formBody);
  if (!resJson.patientId) throw Error('Malformed response from backend');
  return resJson.patientId;
}

export async function unverifiedChatMessageList(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/list', { chatId, afterMessageId });
  return resJson;
}

export async function unverifiedChatSendMessage(chatId, text) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/send-user-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function unverifiedChatMarkResolved(chatId, isResolved) {
  await postJson('/messaging/unverifiedchat/mark-resolved', { chatId, isResolved });
}

export async function unverifiedChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function accountOfficeNameInfo(officeName) {
  const resJson = await postJson('/core/account/office-name-info', { officeName });
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}