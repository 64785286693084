<template>
  <div class="panel">
    <div class="panel-header">
      <router-link to="/messaging/unverified">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <div class="chat-info" ref="chatInfo">
        <img src="/icons/patient.png" alt="Patient Icon" class="chat-icon">
        <span class="chat-name">Unknown</span>
        <span class="chat-label">{{ getPhoneNumber() }}</span>
      </div>
      <div class="button-list">
        <button class="button small mark-resolved" @click="attemptMarkChatResolved(true)"
          v-if="chatInfo && chatInfo.id && !chatInfo.isResolved">
          <div class="unresolved-icon"></div>
          Resolve
        </button>
        <button class="button small" @click="attemptCreatePatient()">
          Create
        </button>
      </div>
    </div>
    <div class="panel-body" ref="body" @scroll="onScroll">
      <div id="messages" ref="messages">
        <div class="loading-status">
          <img src="/loading.svg" class="loading-icon" v-if="loading">
          <span v-else-if="doneLoadingMessages">Chat started by {{ getPhoneNumber() }}</span>
        </div>
        <ChatMessageRow v-for="(message, idx) of messages" :key="message.id" :message="message" :chatWidth="chatWidth"
          :isLastOfKind="isMessageLastOfKind(idx)" :isFirstOfKind="isMessageFirstOfKind(idx)"
          :isFirstOfDay="isMessageFirstOfDay(idx)" :fromSelfSide="isMessageFromSelfSide(message)"
          :hideSelfPicture="isInMobile()" :profilePicture="'/icons/patient.png'" :hasActions="false" />
      </div>
    </div>
    <div class="panel-footer">
      <button class="button small" disabled v-tooltip="'Must create patient profile'">
        <img src="/icons/upload.png" alt="Upload" style="opacity: 0.2">
      </button>
      <button class="button small" @click="$emit('openModal', 'select-template')" v-tooltip="'Templates'">
        <img src="/icons/template.png" alt="Template">
      </button>
      <textarea class="form-control" rows="1" ref="text" v-model="text" @keydown.enter="handleEnterPress"
        placeholder="Send message..." @input="resizeTextArea"></textarea>
      <button class="button primary small" v-if="settings.defaultChatMethod == 'Secure'" disabled>{{secureOnlyTextDisplay()}}</button>
      <button class="button primary small" v-else-if="settings.defaultChatMethod == 'Insecure'"
        @click.prevent.stop="sendTextMessage(false)">Send SMS Message</button>
      <button class="button primary small" id="send" @click.prevent.stop="toggleSendOptions" v-else>
        <img src="/icons/up-arrow.png" alt="Up Arrow">
        <transition name="modal-pop" mode="out-in">
          <div class="send-popup" v-if="showSendOptions">
            <span disabled v-tooltip="'Must create patient profile'"><img src="/icons/mail.png" alt="Email Icon"
                class="lock-icon"> Send Secure Email</span>
            <span disabled v-tooltip="'Must create patient profile'"><img src="/icons/lock.png" alt="Lock Icon"
                class="lock-icon"> Send Secure Text</span>
            <span @click.prevent.stop="sendTextMessage()">Send Text</span>
          </div>
        </transition>
      </button>
    </div>
  </div>
</template>

<script>
import ChatMessageRow from '../ChatMessageRow.vue';
import * as socket from '@/socket.js'
import { unverifiedChatMessageList, unverifiedChatSendMessage, unverifiedChatMarkResolved, unverifiedChatMessageInfo } from '@/api';

const FAKE_LATENCY = 300;

export default {
  name: 'OfficeUnverifiedChatPanel',
  props: {
    chatInfo: Object,
    settings: Object,
  },
  data() {
    return {
      loading: false,
      messages: [],
      doneLoadingMessages: false,
      text: "",
      savedScrollBottom: 0,
      showSendOptions: false,
      showGroupChatDropdown: false,
      chatWidth: 0,
    }
  },
  watch: {
    chatInfo: {
      handler: function (to, from) {
        if (from == null && to != null) {
          this.loadLatestMessages();
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.computeChatWidth);
    socket.addListener('chat-update-event', this.handleUpdateEvent);
    this.computeChatWidth();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.computeChatWidth);
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  methods: {
    secureOnlyTextDisplay() {
      if (this.chatWidth < 1000) {
        return 'Create Patient';
      }
      return 'Create Patient to Send Message'
    },
    async loadLatestMessages() {
      if (!this.chatInfo.id) {
        console.warn('Chat does not exist yet, skipping loading messages');
        this.doneLoadingMessages = true;
        return;
      }

      try {
        this.loading = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await unverifiedChatMessageList(chatId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.loading = false;

        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async loadNextMessages() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const chatId = this.chatInfo.id;
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await unverifiedChatMessageList(chatId, oldestLoadedMessage.id);

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          const body = this.$refs.body;
          if (!body) return;
          const scrollHeight = body.scrollHeight;
          const scrollBottom = scrollHeight - this.savedScrollBottom;
          body.scrollTop = scrollBottom;
        })
      }
    },
    async sendTextMessage() {
      this.showSendOptions = false;

      const text = this.text;
      if (text.length < 1) return;
      this.text = "";
      this.$nextTick(() => {
        this.resizeTextArea();
      })

      try {
        const chatId = this.chatInfo.id;
        const message = await unverifiedChatSendMessage(chatId, text);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }
      } catch (err) {
        console.error('Failed to send message', err);
      } finally {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async attemptMarkChatResolved(val) {
      try {
        await unverifiedChatMarkResolved(this.chatInfo.id, val);
        this.$emit('onChatResolved')
      } catch (err) {
        console.error(err);
      }
    },
    attemptCreatePatient() {
      this.$emit('openModal', 'convert-unverified-chat', {
        mobilePhoneNumber: this.chatInfo.mobilePhoneNumber,
        unverifiedChatId: this.chatInfo.id
      })
    },
    async handleUpdateEvent(data) {
      console.info('Got data', data);

      const { chatId, messageId } = data;
      if (!this.chatInfo || this.chatInfo.id != chatId) {
        return; // Different chat, we don't care...
      }

      if (!messageId || this.isMessageInChat(messageId)) {
        return; // Not a message, or we know about it already
      }

      try {
        // Fetch message
        const message = await unverifiedChatMessageInfo(messageId);

        // Remove message from messa (if it exists), push new version
        this.messages = this.messages.filter(msg => msg.id != message.id);
        this.messages.push(message);

        // Sort chats by date last updated
        this.messages.sort((a, b) => {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        })
      } catch (err) {
        console.error('Failed to fetch message info for update', err);
      }

      // If we were at bottom, scroll even more
      if (this.isAtBottom()) {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    isMessageFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];

      const datePrev = new Date(prev.dateCreated);
      const dateCurr = new Date(curr.dateCreated);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageLastOfDay(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];

      const dateCurr = new Date(curr.dateCreated);
      const dateNext = new Date(next.dateCreated);
      return dateNext.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageFirstOfKind(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      if (this.isMessageFirstOfDay(index)) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];
      return !this.areMessagesGrouped(prev, curr);
    },
    isMessageLastOfKind(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      if (this.isMessageLastOfDay(index)) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];
      return !this.areMessagesGrouped(curr, next);
    },
    isMessageInChat(messageId) {
      return this.messages.find(msg => msg.id == messageId) != null;
    },
    isMessageFromSelfSide(message) {
      return message.sender.type == 'User';
    },
    areMessagesGrouped(message1, message2) {
      const person1 = message1.sender;
      const person2 = message2.sender;

      // Both from patient
      if (person1.type == 'Unknown' && person2.type == 'Unknown') {
        return true;
      }

      // One from patient, one from user
      if (person1.type == 'Unknown' && person2.type == 'User') {
        return false;
      }

      // One from patient, one from user
      if (person1.type == 'User' && person2.type == 'Unknown') {
        return false;
      }

      // Both are user, do their ids match?
      return person1.id == person2.id;
    },
    computeChatWidth() {
      const messagesRef = this.$refs.messages;
      this.chatWidth = messagesRef.offsetWidth;
    },
    isInMobile() {
      return window.innerWidth < 1000;
    },
    getPhoneNumber() {
      const phoneNumber = this.chatInfo.mobilePhoneNumber;
      const digits = phoneNumber.replace(/[^0-9]/g, '');
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
    },
    onScroll(event) {
      if (this.isAtBottom());

      if (this.doneLoadingMessages) {
        return; // Previous fetch returned 0 chats
      }

      const body = event.target;
      const scrollHeight = body.scrollHeight;
      const scrollTop = body.scrollTop;
      const scrollBottom = scrollHeight - scrollTop;

      // Save current scroll position
      this.savedScrollBottom = scrollBottom;

      // Are we at bottom? Try to fetch chats
      if (scrollTop <= 0) {
        this.loadNextMessages();
      }
    },
    scrollToBottom() {
      const body = this.$refs.body;
      if (!body) return;
      body.scrollTop = body.scrollHeight;
    },
    isAtBottom() {
      const body = this.$refs.body;
      if (!body) return false;
      const scrollHeight = body.scrollHeight - body.offsetHeight;
      const scrollBottom = scrollHeight - body.scrollTop;
      return scrollBottom < 20;
    },
    resizeTextArea() {
      const target = this.$refs.text;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    },
    setText(text) {
      this.text = text;
      this.$nextTick(() => {
        this.resizeTextArea();
      })
    },
    handleEnterPress(event) {
      if (event.shiftKey) {
        return;
      }

      event.preventDefault();
      const defaultChatMethod = this.settings.defaultChatMethod;
      if (defaultChatMethod == 'Secure') {
        return;
      } else if (defaultChatMethod == 'Insecure') { // TODO: Protect against accident enter? Also make sure this is on the officepatientchat side, centralize a method for this in a utils cuz it's copy pasta
        this.sendTextMessage(false);
      } else {
        this.openSendOptions();
      }
    },
    openSendOptions() {
      this.showSendOptions = true;
    },
    toggleSendOptions() {
      this.showSendOptions = !this.showSendOptions;
    },
  },
  components: { ChatMessageRow },
  emits: ['openModal', 'onChatResolved']
}
</script>

<style scoped>
.panel {
  flex: 1;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  gap: 0;
}

.panel-header {
  padding: 15px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  align-items: center;
  padding-top: calc(15px + env(safe-area-inset-top));
}

.chat-info {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 15px;
}

.chat-icon-holder {
  position: relative;
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon img {
  display: block;
  width: 20px;
  height: 20px;
}

.chat-name {
  font-weight: bold;
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: none;
}

#messages {
  width: 100%;
  padding: 15px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.panel-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  gap: 10px;
  padding-bottom: calc(15px + env(safe-area-inset-bottom));
}

.panel-footer .button {
  flex-shrink: 0;
  height: 43px;
}

.panel-footer textarea {
  resize: none;
  overflow: hidden;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loading-status span {
  text-align: center;
  padding: 10px;
  background: linear-gradient(to bottom right, #e4ffb9, #ccf4c9);
  color: #2b6332;
  border-radius: 10px;
}

.loading-icon {
  display: block;
  height: 100%;
}

.button img {
  height: 21px;
  display: block;
}

.send-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* Adjust spacing as needed */
}

.send-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* Adjust spacing as needed */
}

#send {
  position: relative;
}

#send img {
  height: 22px;
  display: block;
  filter: invert(1);
}

#send .send-popup {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 10px;
}

#send .send-popup span {
  display: flex;
  align-items: center;
  gap: 8px;
  text-wrap: nowrap;
  background: var(--color-primary);
  color: #FFF;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #FFF;
  box-shadow: 0px 0px 8px #00000022;
}

#send .send-popup span[disabled] {
  background: linear-gradient(to top right, #EEE, #FEFEFE);
  color: #999;
  cursor: not-allowed;
}

#send .send-popup span[disabled] img {
  filter: invert(0);
  opacity: 0.2;
}

.mark-resolved {
  position: relative;
  padding-right: 30px;
}

.unresolved-icon {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #6d3cff;
  border-radius: 50%;
  z-index: 100;
}

.user-icons {
  position: relative;
  width: 50px;
  height: 50px;
}

.user-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #EEE;
  outline: 1px solid #FFF;
  cursor: pointer;
}

.chat-label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .chat-label {
    display: none;
  }

  .back-icon {
    display: block;
  }

  .panel {
    border-radius: 0px;
  }
}

.button-list {
  display: flex;
  align-items: center;
  gap: 15px;
}
</style>