<template>
  <div id="wrapper">
    <div id="full">
      <transition name="modal-appear" mode="out-in">
        <div class="modal-card" style="max-width: 450px" v-if="firstPage">
          <div class="modal-card-header">
            <h1 class="title">Create Account</h1>
            <p class="subtitle">Welcome to the DashQuill platform. Please create an account to get started.</p>
          </div>
          <div class="modal-card-body">
            <form class="form" @submit.prevent="proceedNextPage">
              <div class="form-group">
                <label for="name" class="form-label">Practice Name</label>
                <input id="name" type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="name">
              </div>
              <div class="form-group">
                <label for="practiceType" class="form-label">Practice Type</label>
                <select name="practiceType" id="practiceType" class="form-control" v-model="practiceType">
                  <option value="FamilyMedicine">Family Medicine</option>
                  <option value="InternalMedicine">Internal Medicine</option>
                  <option value="Pediatrics">Pediatrics</option>
                  <option value="GeneralSurgery">General Surgery</option>
                  <option value="Cardiology">Cardiology</option>
                  <option value="ObstetricsAndGynecology">Obstetrics and Gynecology</option>
                  <option value="Psychiatry">Psychiatry</option>
                  <option value="Neurology">Neurology</option>
                  <option value="Radiology">Radiology</option>
                  <option value="EmergencyMedicine">Emergency Medicine</option>
                  <option value="Dermatology">Dermatology</option>
                  <option value="GeneralDentistry">Dentistry</option>
                  <option value="Orthodontics">Orthodontics</option>
                  <option value="PhysicalTherapy">Physical Therapy</option>
                  <option value="Psychology">Psychology</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="form-group">
                <label for="practiceEMR" class="form-label">Practice EMR</label>
                <select id="practiceEMR" name="practiceEMR" class="form-control" v-model="practiceEMR">
                  <option value="PracticeFusion">Practice Fusion</option>
                  <option value="Epic">Epic Systems</option>
                  <option value="Cerner">Cerner</option>
                  <option value="Athenahealth">Athenahealth</option>
                  <option value="EClinicalWorks">eClinicalWorks</option>
                  <option value="NextGen">NextGen Healthcare</option>
                  <option value="Kareo">Kareo</option>
                  <option value="AdvancedMD">AdvancedMD</option>
                  <option value="Other">Other</option>
                  <option value="None">None</option>
                </select>
              </div>
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="proceedNextPage">
              <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
              <span v-else>Continue</span>
            </button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
        <div class="modal-card" style="max-width: 450px" v-else>
          <div class="modal-card-header">
            <h1 class="title">Secure your account</h1>
            <p class="subtitle">Please enter the email and password you would like to use for the account admin.</p>
          </div>
          <div class="modal-card-body">
            <form class="form" @submit.prevent="attemptRegister">
              <div class="form-group">
                <label for="email" class="form-label">Email Address</label>
                <input id="email" type="text" class="form-control" v-model="email">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" v-model="password">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Confirm Password</label>
                <input type="password" class="form-control" v-model="repassword">
              </div>
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="attemptRegister">
              <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
              <span v-else>Create Account</span>
            </button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { createAccount } from '@/api'

const EMAIL_REGEX = /^(.+)@(.+)\.(.+)$/
const PASS_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/

export default {
  name: 'RegisterView',
  data() {
    return {
      name: '',
      practiceType: '',
      practiceEMR: '',
      email: '',
      password: '',
      repassword: '',
      firstPage: true,
      error: '',
      pending: false
    }
  },
  methods: {
    proceedNextPage() {

      if (this.name.length < 3) {
        this.error = 'Name must be at least 3 characters';
        return;
      }

      if (!this.practiceType) {
        this.error = 'Please select a practice type';
        return;
      }

      if (!this.practiceEMR) {
        this.error = 'Please select an EMR';
        return;
      }

      this.firstPage = false;
      this.error = "";
    },
    async attemptRegister() {

      if (!EMAIL_REGEX.test(this.email)) {
        this.error = 'Email must have format like name@email.com';
        return;
      }

      if (this.password != this.repassword) {
        this.error = "Passwords do not match!";
        return;
      }

      if (!PASS_REGEX.test(this.password)) {
        this.error = 'Password must be 8 or more characters, and should have at least one number, uppercase letter, and special symbol.'
        return;
      }

      try {
        this.error = "";
        this.pending = true;
        await createAccount(this.name, this.practiceType, this.practiceEMR, this.email, this.password);
        this.$router.push('/apps');
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    }
  },
}
</script>

<style scoped>
#wrapper {
  background-image: radial-gradient(30% 60%, #ffffff, #e1e6ea);
}

#full {
  background: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e1e6ea' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.error {
  display: block;
  margin: auto;
  text-align: center;
  color: red;
  margin-top: 10px;
}
</style>