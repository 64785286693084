<template>
  <div id="full" :data-chat-open="chatInfo != null">
    <NavigationBar :links="[
      { path: '/messaging/patients', label: 'Patients', hasNotif: false },
      { path: '/messaging/unverified', label: 'Unverified', hasNotif: false },
      { path: '/messaging/groupchats', label: 'Group Chats', hasNotif: false }
    ]" />
    <OfficePatientChatSidebar @openModal="openModal" :chatInfo="chatInfo" />
    <transition name="modal-appear" mode="out-in">
      <OfficePatientChatPanel ref="panel" v-if="chatInfo" :chatInfo="chatInfo" :settings="settings" :msgRef="msgRef"
        @openModal="openModal" @closeModal="closeModal" @onChatCreated="handleChatCreated" @onChatResolved="handleChatResolved"/>
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <StartPatientChatModal v-if="modal.id == 'create-chat'" @openModal="openModal" @closeModal="closeModal" />
            <CreatePatientModal v-else-if="modal.id == 'create-patient'" @closeModal="closeModal"
              :values="modal.data" />
            <OfficeUploadFilesModal v-else-if="modal.id == 'upload-files'" @closeModal="closeModal" :chatInfo="chatInfo"
              @onFileUploaded="handleFileUploaded" />
            <SelectTemplateModal v-else-if="modal.id == 'select-template'" :chatInfo="chatInfo" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" @openModal="openModal" />
            <CreateTemplateModal v-else-if="modal.id == 'create-template'" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" />
            <EditTemplateModal v-else-if="modal.id == 'edit-template'" @closeModal="closeModal"
              :template="modal.data.template" />
            <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
              @selectTemplate="handleSelectTemplate" @closeModal="closeModal" />
            <ForwardMessageModal v-else-if="modal.id == 'forward-message'" :message="modal.data.message"
              :isPatientChat="true" />
            <ManualOptInModal v-else-if="modal.id == 'manual-opt-in'" :patientId="modal.data.patientId" @closeModal="closeModal" @onOptedIn="handleChatOptIn" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { userCheckAuth, fetchPatientChatInfoForPatient, fetchPatient, getMessagingSettings } from '@/api'

import NavigationBar from '../components/navbar/NavigationBar.vue'
import OfficePatientChatSidebar from '../components/officepatientchat/OfficePatientChatsSidebar.vue'
import OfficePatientChatPanel from '../components/officepatientchat/OfficePatientChatPanel.vue'
import StartPatientChatModal from '../modals/StartPatientChatModal.vue'
import CreatePatientModal from '@/modals/CreatePatientModal.vue'
import OfficeUploadFilesModal from '../modals/OfficeUploadFilesModal.vue'
import SelectTemplateModal from '@/modals/SelectTemplateModal.vue'
import CreateTemplateModal from '@/modals/CreateTemplateModal.vue'
import PopulateTemplateModal from '@/modals/PopulateTemplateModal.vue'
import EditTemplateModal from '@/modals/EditTemplateModal.vue'
import ForwardMessageModal from '@/modals/ForwardMessageModal.vue'
import ManualOptInModal from '../modals/ManualOptInModal.vue'
import * as socket from '../socket.js'

export default {
  name: 'OfficePatientChatsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,
      settings: {},
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        const patientId = to.params.id;
        this.chatInfo = null;
        if (patientId) {
          this.loadChatInfo(patientId);
        }
      },
      immediate: true
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          const currentUrl = window.location.href;
          this.$router.push('/login?ref=' + currentUrl);
        }
      });
  },
  computed: {
    msgRef() {
      return this.$route.query.ref || "";
    }
  },
  mounted() {
    this.listenForUpdates();
    this.attemptLoadSettings();
    socket.addListener('chat-update-event', this.handleUpdateEvent);
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleChatCreated(chat) {
      this.chatInfo = chat;
    },
    handleSelectTemplate(text) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.setText(text);
      this.closeModal();
    },
    handleFileUploaded(file, messageType, errorCallback) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.handleFileUploaded(file, messageType, errorCallback);
    },
    handleChatResolved() {
      this.chatInfo.isResolved = true;
    },
    handleChatOptIn() {
      this.chatInfo.patient.smsOptInStatus = "Yes"
    },
    async loadChatInfo(patientId) {
      try {
        const chatInfo = await fetchPatientChatInfoForPatient(patientId);
        if (chatInfo) {
          // Got a chat, lets show it
          this.chatInfo = chatInfo;
        } else {
          // Got a 404, set up a dummy patient chat
          const patient = await fetchPatient(patientId);
          this.chatInfo = { patient }
        }

      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
      }
    },
    async handleUpdateEvent(data) {
      const { chatId } = data;
      console.log('Updating chat', data);

      if (!this.chatInfo || this.chatInfo.id != chatId) {
        console.info('Ignoring....');
        return; // Not current chat, ignore
      }

      try {
        // Refetch chat (in case there's some update to it)
        const chat = await fetchPatientChatInfoForPatient(this.chatInfo.patient.id);
        this.chatInfo = chat;
        console.info('Updated chat: ', chat);

      } catch (err) {
        console.error('Failed to fetch chat info for updated chat', err);
      }
    },
    async listenForUpdates() {
      if (!socket.isConnectedToSocket()) {
        await socket.connectToSocket();
        console.info('Connected to socket');
      }

      await socket.joinAccountRoom();
      console.info('Joined account room');
    },
    async attemptLoadSettings() {
      try {
        this.settings = await getMessagingSettings();
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: {
    NavigationBar,
    OfficePatientChatSidebar,
    OfficePatientChatPanel,
    StartPatientChatModal,
    CreatePatientModal,
    CreateTemplateModal,
    OfficeUploadFilesModal,
    SelectTemplateModal,
    PopulateTemplateModal,
    EditTemplateModal,
    ForwardMessageModal,
    ManualOptInModal
  }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1000px) {

  #full[data-chat-open="true"] {
    padding: 0px;
  }

  #full[data-chat-open="true"]>nav {
    display: none;
  }
}
</style>