<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Edit Patient Profile</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" v-if="patient" @submit.prevent="">
        <div class="form-column">
          <div class="form-group">
            <label for="firstName" class="form-label">First Name</label>
            <input id="firstName" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.firstName">
          </div>
          <div class="form-group">
            <label for="middleName" class="form-label">Middle Name</label>
            <input id="middleName" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.middleName">
          </div>
          <div class="form-group">
            <label for="lastName" class="form-label">Last Name</label>
            <input id="lastName" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.lastName">
          </div>
        </div>
        <div class="form-column">
          <div class="form-group">
            <label for="dateOfBirth" class="form-label">Date of Birth</label>
            <input id="dateOfBirth" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.dateOfBirth">
          </div>
          <div class="form-group">
            <label for="emailAddress" class="form-label">Email Address</label>
            <input id="emailAddress" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.emailAddress">
          </div>
          <div class="form-group">
            <label for="mobilePhoneNumber" class="form-label">Mobile Phone Number</label>
            <input id="mobilePhoneNumber" type="text" class="form-control" data-lpignore="true" autocomplete="off" data-form-type="other" v-model="patient.mobilePhoneNumber">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptUpdatePatient">Save Changes</button>
    </div>
  </div>
</template>

<script>
import { fetchPatient, updatePatient } from '../../api';

export default {
  name: "PatientChartModal",
  props: {
    patientId: String
  },
  data() {
    return {
      patient: null
    }
  },
  mounted() {
    this.attemptFetchPatient();
  },
  methods: {
    async attemptFetchPatient() {
      try {
        const patient = await fetchPatient(this.patientId);
        this.patient = patient;
      } catch (err) {
        console.error(err);
      }
    },
    async attemptUpdatePatient() {
      const p = this.patient;
      const fields = {
        firstName: p.firstName,
        middleName: p.middleName,
        lastName: p.lastName,
        dateOfBirth: p.dateOfBirth,
        emailAddress: p.emailAddress ? p.emailAddress : undefined,
        mobilePhoneNumber: p.mobilePhoneNumber ? p.mobilePhoneNumber : undefined
      }

      try {
        const patient = await updatePatient(this.patientId, fields);
        this.$emit('onPatientUpdated', patient);
      } catch (err) {
        console.error(err);
      }
    }
  },
  emits: ['openModal', 'onPatientUpdated']
};
</script>

<style scoped>
.modal-card {
  max-width: 640px;
}

.profile-picture {
  margin: auto;
  width: 142px;
  height: 142px;
  background: #EEE;
  border: 1px solid #DDD;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-picture img {
  width: 50%;
  height: 50%;
  display: block;
}

.form {
  flex-direction: row;
}

@media screen and (max-width: 1000px) {
  .form {
    flex-direction: column;
  }
}

</style>