<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Forward Message</h1>
      <p class="subtitle">
        Select which group chat you would like to forward this message to.
      </p>
    </div>
    <div class="modal-card-body">
      <div class="chat-list-items" @scroll="onScroll" ref="list">
        <GroupChatListItem v-for="chat of chats" :key="chat.id" :chat="chat" @click.prevent="attemptForwardMessage(chat)" />
        <div class="loading-status" v-if="!doneLoadingChats">
          <img src="/loading.svg" class="loading-icon" v-if="loading">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listGroupChats, groupChatForwardMessage } from '@/api'
import GroupChatListItem from '../components/officegroupchat/GroupChatListItem.vue'

const FAKE_LATENCY = 500;

export default {
  name: "ForwardMessageModal",
  props: {
    message: Object,
    isPatientChat: Boolean
  },
  data() {
    return {
      loading: false,
      chats: [],
      doneLoadingChats: false,
    };
  },
  mounted() {
    this.loadLatestChats();
  },
  methods: {
    async loadLatestChats() {
      try {
        this.loading = true;
        const { chats, isLast } = await listGroupChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
      }
    },
    async loadNextChats() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await listGroupChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        for (let chat of chats) {
          this.chats.push(chat);
        }

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.loading = false;
      }
    },
    onScroll(event) {
      if (this.doneLoadingChats) {
        return; // Previous fetch returned 0 chats
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.loadNextChats();
      }
    },
    async attemptForwardMessage(chat) {
      try {
        const result = await groupChatForwardMessage(this.message.id, chat.id, this.isPatientChat);
        console.info(result);
        this.$router.push('/messaging/groupchats/' + chat.id);
      } catch (err) {
        alert(err);
      }

      // this.$router.push('/groupchats');
    }
  },
  components: { GroupChatListItem },
  emits: ["openModal", "closeModal"],
};
</script>

<style scoped>
.modal-card {
  max-width: 600px;
}

.chat-list-items>.chat-list-item:hover {
  background: var(--color-fill-med);
}

.modal-card-body {
  height: 400px;
}

</style>