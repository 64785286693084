<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Group Chat</h1>
      <p class="subtitle">Select team members that you want to add to this chat. You will be able to invite members
        later too.</p>
    </div>
    <div class="modal-card-body">
      <div class="user-list-item" v-for="user of users" :key="user.id" @click="onToggleUser(user.id)">
        <img :src="getProfilePicture(user)" alt="User Picture" class="user-picture"
          @click.stop.prevent="$emit('onEditPicture', user)">
        <span class="user-name">{{ user.name }}</span>
        <span class="user-role">{{ user.practiceRole }}</span>
        <div class="spacer" style="flex: 1"></div>
        <CheckBox size="25px" thickness="3px" :isChecked="isUserSelected(user.id)" />
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="openNextModal">Select {{ selected.size }} Users</button>
    </div>
  </div>
</template>

<script>
import { listUsers } from '@/api';
import CheckBox from '../../components/CheckBox.vue';

export default {
  name: "CreateTeamChatModal",
  props: {
    userInfo: Object
  },
  data() {
    return {
      name: '',
      users: [],
      selected: new Set()
    }
  },
  mounted() {
    this.attemptFetchUsers();

    // Always add themselves
    if (this.userInfo) {
      this.selected.add(this.userInfo.id);
    }
  },
  methods: {
    onToggleUser(userId) {
      if (this.userInfo && this.userInfo.id == userId) {
        return;
      }

      if (this.selected.has(userId)) {
        this.selected.delete(userId);
      } else {
        this.selected.add(userId);
      }
    },
    isUserSelected(userId) {
      return this.selected.has(userId)
    },
    openNextModal() {
      const selectedUsers = this.users.filter(user => this.isUserSelected(user.id));
      this.$emit('openModal', 'name-group-chat', { users: selectedUsers })
    },
    async attemptFetchUsers() {
      try {
        const users = await listUsers();
        this.users = users;

        if (this.userInfo) {
          this.users = this.users.filter(usr => usr.id != this.userInfo.id);
          this.users.unshift(this.userInfo);
        }
        
      } catch (err) {
        console.error('Error', err);
      }
    },
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    }
  },
  components: { CheckBox },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
}

.user-list-item:hover {
  background: var(--color-fill-med);
}

.user-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: var(--color-txt-med);
}
</style>