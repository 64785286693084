<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Template</h1>
      <p class="subtitle">
        Save frequently-sent messages so that you can send them to patients in one click.
      </p>
    </div>
    <div class="modal-card-body">
      <form class="form" action="" @submit.prevent="attemptCreateTemplate">
        <div class="form-group">
          <label for="name" class="form-label">Template Name</label>
          <input id="name" type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="name">
        </div>
        <div class="form-group">
          <label for="text" class="form-label">Template Text (Message)</label>
          <textarea name="text" id="text" rows="3" class="form-control" v-model="text"></textarea>
        </div>
        <input type="text" class="submit" hidden>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptCreateTemplate">Create Template</button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import {createTemplate} from '@/api';

export default {
  name: "CreateTemplateModal",
  data() {
    return {
      name: "",
      text: "",
      error: "",
      pending: false
    };
  },
  methods: {
    async attemptCreateTemplate() {
      try {
        this.pending = true;
        this.error = "";

        await createTemplate(this.name, this.text);
        this.$emit('closeModal');

      } catch (err) {
        console.error(err);
        this.error = err;
      } finally {
        this.pending = false;
      }
    }
  },
  components: {},
  emits: ["openModal", "closeModal"],
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

textarea {
  resize: vertical;
}
</style>