<template>
  <div id="full" :data-chat-open="chatInfo != null">
    <NavigationBar :links="[
      { path: '/messaging/patients', label: 'Patients', hasNotif: false },
      { path: '/messaging/unverified', label: 'Unverified', hasNotif: false },
      { path: '/messaging/groupchats', label: 'Group Chats', hasNotif: false }
    ]" />
    <OfficeUnverifiedChatsSidebar @openModal="openModal" />
    <transition name="modal-appear" mode="out-in">
      <OfficeUnverifiedChatPanel ref="panel" v-if="chatInfo && settings" :chatInfo="chatInfo" :settings="settings"
        @openModal="openModal" @closeModal="closeModal" @onChatResolved="handleChatResolved" />
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <SelectTemplateModal v-if="modal.id == 'select-template'" :chatInfo="chatInfo" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" @openModal="openModal" />
            <CreateTemplateModal v-else-if="modal.id == 'create-template'" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" />
            <EditTemplateModal v-else-if="modal.id == 'edit-template'" @closeModal="closeModal"
              :template="modal.data.template" />
            <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
              @selectTemplate="handleSelectTemplate" @closeModal="closeModal" />
            <ConvertUnverifiedChatModal v-else-if="modal.id == 'convert-unverified-chat'"
              :mobilePhoneNumber="modal.data.mobilePhoneNumber" :unverifiedChatId="modal.data.unverifiedChatId" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as socket from '../socket.js'
import { userCheckAuth, getMessagingSettings, unverifiedChatFetchInfo } from '@/api'
import NavigationBar from '../components/navbar/NavigationBar.vue'
import OfficeUnverifiedChatsSidebar from '../components/officeunverifiedchat/OfficeUnverifiedChatsSidebar.vue'
import OfficeUnverifiedChatPanel from '../components/officeunverifiedchat/OfficeUnverifiedChatPanel.vue';
import SelectTemplateModal from '../modals/SelectTemplateModal.vue';
import CreateTemplateModal from '../modals/CreateTemplateModal.vue';
import EditTemplateModal from '../modals/EditTemplateModal.vue';
import PopulateTemplateModal from '../modals/PopulateTemplateModal.vue';
import ConvertUnverifiedChatModal from '../modals/ConvertUnverifiedChatModal.vue'

export default {
  name: 'OfficeUnverifiedChatView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,
      userInfo: null,
      settings: null
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        const chatId = to.params.id;
        this.chatInfo = null;
        if (chatId) {
          this.loadChatInfo(chatId);
        }
      },
      immediate: true
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          const currentUrl = window.location.href;
          this.$router.push('/login?ref=' + currentUrl);
        }
      });
  },
  mounted() {
    this.listenForUpdates();
    this.attemptLoadSettings();
    socket.addListener('chat-update-event', this.handleUpdateEvent);
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleChatResolved() {
      this.chatInfo.isResolved = true;
    },
    async handleUpdateEvent(data) {
      const { chatId } = data;
      console.log('Updating chat', data);

      if (!this.chatInfo || this.chatInfo.id != chatId) {
        console.info('Ignoring....');
        return; // Not current chat, ignore
      }

      try {
        // Refetch chat (in case there's some update to it)
        const chat = await unverifiedChatFetchInfo(this.chatInfo.id);
        this.chatInfo = chat;
        console.info('Updated chat: ', chat);

      } catch (err) {
        console.error('Failed to fetch chat info for updated chat', err);
      }
    },
    async listenForUpdates() {
      if (!socket.isConnectedToSocket()) {
        await socket.connectToSocket();
        console.info('Connected to socket');
      }

      await socket.joinAccountRoom();
      console.info('Joined account room');
    },
    async loadChatInfo(chatId) {
      try {
        const chatInfo = await unverifiedChatFetchInfo(chatId);
        this.chatInfo = chatInfo;
      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
      }
    },
    async attemptLoadSettings() {
      try {
        this.settings = await getMessagingSettings();
      } catch (err) {
        console.error(err);
      }
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleSelectTemplate(text) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.setText(text);
      this.closeModal();
    },
  },
  components: { NavigationBar, OfficeUnverifiedChatsSidebar, OfficeUnverifiedChatPanel, SelectTemplateModal, CreateTemplateModal, EditTemplateModal, PopulateTemplateModal, ConvertUnverifiedChatModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  #full[data-chat-open="true"] {
    padding: 0px;
  }

  #full[data-chat-open="true"]>nav {
    display: none;
  }
}
</style>