<template>
  <div id="full">
    <NavigationBar :links="[{ path: '/apps', label: 'My Apps', hasNotif: false }, { path: '/patients', label: 'Patients', hasNotif: false }]" />
    <AccountPanel />
    <transition name="modal-appear" mode="out-in">
      <GeneralSettingsPanel v-if="$route.params.page == 'general'" />
      <MessagingSettingsPanel v-else-if="$route.params.page == 'messaging'" />
      <UserListPanel v-else-if="$route.params.page == 'users'" ref="panel" @openModal="openModal" />
      <UserMessagingSettingsPanel v-else-if="$route.params.page == 'user-messaging'" />
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <InviteUserModal v-if="modal.id == 'invite-user'" @onUserInvited="handleUserInvited" />
            <RemoveUserModal v-else-if="modal.id == 'remove-user'" :user="modal.data.user"
              @onUserRemoved="handleUserRemoved" />
            <RevokeInviteModal v-else-if="modal.id == 'revoke-invite'" :invite="modal.data.invite"
              @onInviteRevoked="handleInviteRevoked" />
            <UserPictureModal v-else-if="modal.id == 'user-picture'" :user="modal.data.user" @closeModal="closeModal"
              @onUserPictureUpdated="handleUserPictureUpdated" />
            <EditUserModal v-else-if="modal.id == 'edit-user'" :user="modal.data.user"
              @onUserUpdated="handleUserUpdated" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavigationBar from '../components/navbar/NavigationBar.vue'
import AccountPanel from '../components/account/AccountPanel.vue'
import UserListPanel from '../components/account/UserListPanel.vue'
import GeneralSettingsPanel from '../components/account/GeneralSettingsPanel.vue'
import MessagingSettingsPanel from '../components/account/MessagingSettingsPanel.vue'
import UserMessagingSettingsPanel from '../components/account/UserMessagingSettingsPanel.vue'
import InviteUserModal from '../modals/account/InviteUserModal.vue'
import RemoveUserModal from '../modals/account/RemoveUserModal.vue'
import RevokeInviteModal from '../modals/account/RevokeInviteModal.vue'
import UserPictureModal from '../modals/account/UserPictureModal.vue'
import EditUserModal from '../modals/account/EditUserModal.vue'
import { userCheckAuth } from '@/api'

export default {
  name: 'AccountView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          this.$router.push('/login?ref=/settings');
        }
      })
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleUserInvited(invitation) {
      this.closeModal();

      // Pass patient to panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleUserInvited(invitation);
    },
    handleUserRemoved(userId) {
      this.closeModal();

      // Remove user from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleUserRemoved(userId);
    },
    handleInviteRevoked(invitationId) {
      this.closeModal();

      // Remove invite from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleInviteRevoked(invitationId);
    },
    handleUserUpdated(user) {
      this.closeModal();

      // Remove user from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleUserUpdated(user);
    },
    handleUserPictureUpdated(userId, key) {
      this.closeModal();

      // Remove user from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleUserPictureUpdated(userId, key);
    }
  },
  components: { NavigationBar, AccountPanel, UserListPanel, InviteUserModal, RemoveUserModal, RevokeInviteModal, MessagingSettingsPanel, GeneralSettingsPanel, UserPictureModal, EditUserModal, UserMessagingSettingsPanel }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>