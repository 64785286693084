<template>
  <div class="panel">
    <div class="panel-header">
      <h2>Upcoming Meetings</h2>
    </div>
    <div class="panel-body">
      <div id="meetings">
        <MeetingListItem v-for="(meeting, idx) of meetings" :key="meeting.id" :meeting="meeting"
          :isMeetingFirstOfDay="isMeetingFirstOfDay(idx)" @openModal="openModal"/>
      </div>
    </div>
  </div>
</template>

<script>
import { listMeetings } from '@/api';
import MeetingListItem from './MeetingListItem.vue'

export default {
  name: 'OfficeMeetingsListPanel',
  data() {
    return {
      meetings: []
    }
  },
  mounted() {
    this.attemptFetchMeetings();
  },
  methods: {
    openModal(id, data) {
      this.$emit('openModal', id, data);
    },
    async attemptFetchMeetings() {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const meetings = await listMeetings(timeZone);
        this.meetings = meetings;
      } catch (err) {
        console.error('Failed to fetch meetings', err);
      }
    },
    handleMeetingCreated(meeting) {
      this.meetings.push(meeting);
      this.meetings.sort((a, b) => {
        return new Date(a.meetingTime) - new Date(b.meetingTime)
      })
    },
    isMeetingFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.meetings[index - 1];
      const curr = this.meetings[index];

      const datePrev = new Date(prev.meetingTime);
      const dateCurr = new Date(curr.meetingTime);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    handleNotificationSent(meeting, notif) {
      console.log(meeting, notif);
      const meet = this.meetings.find(m => m.id == meeting.id);
      if (!meet) return;
      meet.reminderSendDates.push(notif);
    }
  },
  components: { MeetingListItem },
  emits: ['openModal']
}
</script>

<style scoped>
.panel {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel-body {
  height: 1px;
  flex: 1;
  margin: -10px;
}

#meetings {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>