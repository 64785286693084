<template>
  <div class="panel">
    <div class="panel-header">
      <router-link to="/settings">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <h2>Messaging</h2>
    </div>
    <div class="panel-body form" v-if="settings">
      <div class="form-group">
        <label for="" class="form-label">Messaging SMS Number</label>
        <input type="text" class="form-control" :value="settings.smsSenderNumber" disabled>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Default chat method</label>
        <select name="" id="" class="form-control" v-model="settings.defaultChatMethod">
          <option value="Secure">Secure SMS (HIPAA Compliant)</option>
          <option value="Insecure">Unprotected SMS</option>
          <option value="Combination">Combination</option>
        </select>
        <p class="warning" v-if="settings.defaultChatMethod == 'Insecure'">Since SMS is by default not HIPAA compliant, your practice must not send any protected health information (PHI) via Messaging.</p>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Opt In Message</label>
        <textarea type="textarea" class="form-control" rows="3" :value="settings.optInMessage" disabled></textarea>
      </div>
    </div>
    <button class="button small" v-if="haveSettingsChanged()" @click="attemptSaveSettings">Save Changes</button>
  </div>
</template>

<script>
import { getMessagingSettings, setMessagingSettings } from '../../api';

export default {
  name: 'MessagingSettingsPanel',
  data() {
    return {
      previousSettings: null,
      settings: null,
      error: ''
    }
  },
  mounted() {
    this.attemptGetSettings();
  },
  methods: {
    async attemptGetSettings() {
      try {
        const settings = await getMessagingSettings();
        this.settings = settings;
        this.previousSettings = structuredClone(settings);
      } catch (err) {
        this.error = err.message;
      }
    },
    async attemptSaveSettings() {
      try {
        await setMessagingSettings({
          defaultChatMethod: this.settings.defaultChatMethod
        });
        this.previousSettings = structuredClone(this.settings);
      } catch (err) {
        this.error = err.message;
      }
    },
    haveSettingsChanged() {
      return JSON.stringify(this.settings) != JSON.stringify(this.previousSettings);
    }
  },
}
</script>

<style scoped>
.form {
  max-width: 500px;
}

.panel {
  flex: 1;
  align-items: flex-start;
}

.warning {
  margin-top: 10px;
  padding: 10px;
  background: rgb(255, 240, 191);
}

textarea {
  resize: none;
}

.panel-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .back-icon {
    display: block;
  }
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
}
</style>