<template>
  <div class="user-list-table" @scroll="onScroll">
    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th width="30%" style="padding-left: 100px">User Name</th>
          <th width="20%">User Role</th>
          <th width="30%">Email Address</th>
          <th>Date Joined</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user of users" :key="user.id" @click="$emit('onEditUser', user)">
          <td class="column-user-name">
            <img :src="getProfilePicture(user)" alt="User Picture" class="user-picture" @click.stop.prevent="$emit('onEditPicture', user)">
            <span class="user-name">
              {{ user.name }}
              <img src="/icons/crown.png" alt="Admin User" v-if="user.isAccountOwner">
              <img src="/icons/support.png" alt="Admin User" v-if="user.isAdmin" >
            </span>
          </td>
          <td class="column-user-role">
            <span class="user-role">{{ user.practiceRole }}</span>
          </td>
          <td class="column-user-email">
            <span class="user-email">{{ user.email }}</span>
          </td>
          <td class="column-date-joined">
            <span class="date-joined">{{ getFormattedDate(user.dateJoined) }}</span>
          </td>
          <td>
            <img src="/icons/trash.png" class="remove-user-icon" alt="Archive Patient Buttton" @click.stop.prevent="$emit('onRemoveUser', user)">
          </td>
        </tr>
        <tr v-for="invite of invites" :key="invite.id">
          <td class="column-user-name">
            <div class="invite-icon"></div>
            <span class="user-name">{{ invite.name }}</span>
          </td>
          <td class="column-user-role">
            <span class="user-role">{{ invite.practiceRole }}</span>
          </td>
          <td class="column-user-email">
            <span class="user-email">{{ invite.email }}</span>
          </td>
          <td class="column-date-joined">
            <span class="date-joined">Pending</span>
          </td>
          <td>
            <img src="/icons/trash.png" class="remove-user-icon" alt="Archive Patient Buttton" @click.stop.prevent="$emit('onRevokeInvite', invite)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UserListTable',
  props: {
    users: Array,
    invites: Array
  },
  methods: {
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getFormattedDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
  },
  emits: ['onEditUser', 'onEditPicture', 'onRemoveUser', 'onRevokeInvite']
}
</script>

<style scoped>
.user-list-table {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-light);
  border-radius: 5px;
  overflow: auto;
}

table {
  min-width: 100%;
  table-layout: fixed;
}

thead {
  position: sticky;
  top: 0;
  background: var(--color-fill-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

th {
  height: 50px;
  padding: 0px 25px;
  text-align: left;
  font-weight: 400;
  border: 1px solid var(--color-border-med);
  text-wrap: nowrap;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  background: var(--color-fill-med);
}

th:not(:first-child) {
  border-left: none;
}

th:not(:last-child) {
  border-right: none;
}

td {
  height: 70px;
  padding: 0px 25px;
}

.column-user-name {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: bold;
}

.user-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.remove-user-icon {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.2;
}

.remove-user-icon:hover {
  opacity: 1;
}

.invite-icon {
  width: 48px;
  height: 48px;
  border: 2px dashed #CCC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-name {
  display: flex;
  gap: 10px;
  align-items: center;
  text-wrap: nowrap;
}

.user-name img {
  display: block;
  height: 20px;
}
</style>