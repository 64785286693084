<template>
  <div id="full">
    <NavigationBar :links="[{ path: '/apps', label: 'My Apps', hasNotif: false }, { path: '/patients', label: 'Patients', hasNotif: false }]" />
    <PatientListPanel ref="panel" @openModal="openModal" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <PatientChartModal v-if="modal.id == 'patient-chart'" :patientId="modal.data.patientId"
              @openModal="openModal" @onPatientUpdated="handlePatientUpdated" />
            <CreatePatientModal v-else-if="modal.id == 'create-patient'" @closeModal="closeModal"
              @onPatientCreated="handlePatientCreated" />
            <ArchivePatientModal v-else-if="modal.id == 'archive-patient'" :patient="modal.data.patient"
              @closeModal="closeModal" @onPatientArchived="handlePatientArchived" />
            <PatientPictureModal v-else-if="modal.id == 'patient-picture'" :patient="modal.data.patient"
              @closeModal="closeModal" @onPatientPictureUpdated="handlePatientPictureUpdated" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { userCheckAuth } from '../api';
import NavigationBar from '../components/navbar/NavigationBar.vue';
import PatientListPanel from '../components/patients/PatientListPanel.vue';
import PatientChartModal from '../modals/patients/PatientChartModal.vue';
import CreatePatientModal from '../modals/patients/CreatePatientModal.vue';
import ArchivePatientModal from '../modals/patients/ArchivePatientModal.vue';
import PatientPictureModal from '../modals/patients/PatientPictureModal.vue';

export default {
  name: "PatientsView",
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          this.$router.push('/login?ref=/patients');
        }
      })
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handlePatientUpdated(patient) {
      this.closeModal();

      // Pass patient to panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handlePatientUpdated(patient);
    },
    handlePatientCreated(patient) {
      this.closeModal();

      // Pass patient to panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handlePatientCreated(patient);
    },
    handlePatientArchived(patientId) {
      this.closeModal();

      // Pass patient to panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handlePatientArchived(patientId);
    },
    handlePatientPictureUpdated(patientId, pictureKey) {
      this.closeModal();

      // Pass patient to panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handlePatientPictureUpdated(patientId, pictureKey);
    }
  },
  components: { NavigationBar, PatientListPanel, PatientChartModal, CreatePatientModal, ArchivePatientModal, PatientPictureModal }
}
</script>

<style scoped>
</style>