<template>
  <div class="panel">
    <div class="panel-header">
      <router-link to="/messaging/groupchats">
        <img src="/icons/back.png" alt="Back Icon" class="back-icon">
      </router-link>
      <div class="chat-info" ref="chatInfo">
        <div class="user-icons" :style="{ width: `${25 + chatInfo.users.length * 15}px` }"
          @click.prevent="setShowingGroupChatDropdown(true)">
          <img :src="getProfilePicture(user)" v-for="(user, idx) of chatInfo.users" :key="user.id" alt="Chat Icon"
            class="user-icon" :style="{ transform: `translateX(${idx * 15}px)` }">
          <transition name="modal-appear" mode="out-in">
            <GroupChatDropdown v-if="showGroupChatDropdown" :users="chatInfo.users" @onAddMember="handleAddMember"
              @onLeaveChat="handleLeaveChat" @closePopup="setShowingGroupChatDropdown(false)" />
          </transition>
        </div>
        <span class="chat-name">{{ chatInfo.name }}</span>
        <span class="chat-label">{{ chatInfo.users.length }} Members</span>
      </div>
    </div>
    <div class="panel-body" ref="body" @scroll="onScroll">
      <div id="messages" ref="messages">
        <div class="loading-status">
          <img src="/loading.svg" class="loading-icon" v-if="loading">
          <span v-else-if="doneLoadingMessages">Beginning of group chat</span>
        </div>
        <ChatMessageRow v-for="(message, idx) of messages" :key="message.id" :message="message" :chatWidth="chatWidth"
          :isLastOfKind="isMessageLastOfKind(idx)" :isFirstOfKind="isMessageFirstOfKind(idx)"
          :isFirstOfDay="isMessageFirstOfDay(idx)" :fromSelfSide="isMessageFromSelfSide(message)"
          :hideSelfPicture="isInMobile()" :profilePicture="getProfilePicture(message.sender)" :hasActions="false" />
      </div>
    </div>
    <div class="panel-footer">
      <button class="button small" @click="$emit('openModal', 'upload-files')">
        <img src="/icons/upload.png" alt="Upload">
      </button>
      <textarea class="form-control" rows="1" ref="text" v-model="text" @keydown.enter.prevent="sendTextMessage"
        placeholder="Send message..." @input="resizeTextArea"></textarea>
      <button class="button primary small" @click.prevent.stop="sendTextMessage">Send</button>
    </div>
  </div>
</template>

<script>
import * as socket from '@/socket.js'
import { groupChatListMessages, groupChatMessageInfo, groupChatSendUserText, groupChatSendUserFile } from '@/api';
import GroupChatDropdown from './GroupChatDropdown.vue';
import ChatMessageRow from '../ChatMessageRow.vue';

const FAKE_LATENCY = 300;

export default {
  name: 'OfficeGroupChatPanel',
  props: {
    chatInfo: Object,
    userInfo: Object,
    settings: Object,
  },
  data() {
    return {
      loading: false,
      messages: [],
      doneLoadingMessages: false,
      text: "",
      savedScrollBottom: 0,
      showSendOptions: false,
      showGroupChatDropdown: false,
    }
  },
  watch: {
    chatInfo: {
      handler: function (to, from) {
        if (from == null && to != null) {
          this.loadLatestMessages();
        }
      },
      immediate: true
    }
  },
  mounted() {
    socket.addListener('chat-update-event', this.handleUpdateEvent);
    window.addEventListener('click', this.onClickPage);
    window.addEventListener('resize', this.computeChatWidth);
    this.computeChatWidth();
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
    window.removeEventListener('click', this.onClickPage);
    window.removeEventListener('resize', this.computeChatWidth);
  },
  methods: {
    async loadLatestMessages() {
      if (!this.chatInfo.id) {
        console.warn('Chat does not exist yet, skipping loading messages');
        this.doneLoadingMessages = true;
        return;
      }

      try {
        this.loading = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await groupChatListMessages(chatId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.loading = false;

        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async loadNextMessages() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const chatId = this.chatInfo.id;
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await groupChatListMessages(chatId, oldestLoadedMessage.id);

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          const body = this.$refs.body;
          if (!body) return;
          const scrollHeight = body.scrollHeight;
          const scrollBottom = scrollHeight - this.savedScrollBottom;
          body.scrollTop = scrollBottom;
        })
      }
    },
    async sendTextMessage() {
      const text = this.text;
      if (text.length < 1) return;
      this.text = "";
      this.$nextTick(() => {
        this.resizeTextArea();
      })

      try {
        let chatId = this.chatInfo.id;
        const message = await groupChatSendUserText(chatId, text);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }
      } catch (err) {
        console.error('Failed to send message', err);
      } finally {
        this.showSendOptions = false;
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async handleUpdateEvent(data) {
      const { chatId, messageId } = data;
      if (!this.chatInfo || this.chatInfo.id != chatId) {
        return; // Different chat, we don't care...
      }

      if (!messageId || this.isMessageInChat(messageId)) {
        return; // Not a message, or we know about it already
      }

      try {
        // Fetch message
        const message = await groupChatMessageInfo(messageId);

        // Remove message from messa (if it exists), push new version
        this.messages = this.messages.filter(msg => msg.id != message.id);
        this.messages.push(message);

        // Sort chats by date last updated
        this.messages.sort((a, b) => {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        })
      } catch (err) {
        console.error('Failed to fetch message info for update', err);
      }

      // If we were at bottom, scroll even more
      if (this.isAtBottom()) {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async handleFileUploaded(file, errorCallback) {
      try {
        const chatId = this.chatInfo.id;
        const message = await groupChatSendUserFile(chatId, file);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }

        this.$emit('closeModal');
      } catch (err) {
        console.error('Failed to send message', err);
        errorCallback(err);
      } finally {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    getUserProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    isMessageFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];

      const datePrev = new Date(prev.dateCreated);
      const dateCurr = new Date(curr.dateCreated);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageLastOfDay(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];

      const dateCurr = new Date(curr.dateCreated);
      const dateNext = new Date(next.dateCreated);
      return dateNext.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageFirstOfKind(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      if (this.isMessageFirstOfDay(index)) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];
      return !this.areMessagesGrouped(prev, curr);
    },
    isMessageLastOfKind(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      if (this.isMessageLastOfDay(index)) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];
      return !this.areMessagesGrouped(curr, next);
    },
    isMessageInChat(messageId) {
      return this.messages.find(msg => msg.id == messageId) != null;
    },
    isMessageFromSelfSide(message) {
      return message.sender.id == this.userInfo.id;
    },
    areMessagesGrouped(message1, message2) {
      const person1 = message1.sender;
      const person2 = message2.sender;
      return person1.id == person2.id;
    },
    computeChatWidth() {
      const messagesRef = this.$refs.messages;
      this.chatWidth = messagesRef.offsetWidth;
    },
    isInMobile() {
      return window.innerWidth < 1000;
    },
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    onScroll(event) {
      if (this.isAtBottom());

      if (this.doneLoadingMessages) {
        return; // Previous fetch returned 0 chats
      }

      const body = event.target;
      const scrollHeight = body.scrollHeight;
      const scrollTop = body.scrollTop;
      const scrollBottom = scrollHeight - scrollTop;

      // Save current scroll position
      this.savedScrollBottom = scrollBottom;

      // Are we at bottom? Try to fetch chats
      if (scrollTop <= 0) {
        this.loadNextMessages();
      }
    },
    scrollToBottom() {
      const body = this.$refs.body;
      if (!body) return;
      body.scrollTop = body.scrollHeight;
    },
    isAtBottom() {
      const body = this.$refs.body;
      if (!body) return false;
      const scrollHeight = body.scrollHeight - body.offsetHeight;
      const scrollBottom = scrollHeight - body.scrollTop;
      return scrollBottom < 20;
    },
    resizeTextArea() {
      const target = this.$refs.text;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    },
    setShowingGroupChatDropdown(val) {
      this.showGroupChatDropdown = val;
    },
    onClickPage(event) {
      const target = event.target;

      const chatInfoRef = this.$refs.chatInfo;
      if (!chatInfoRef || !chatInfoRef.contains(target)) {
        this.setShowingGroupChatDropdown(false);
      }
    },
    handleAddMember() {
      this.setShowingGroupChatDropdown(false);
      this.$emit('openModal', 'add-member');
    },
    handleLeaveChat() {
      this.setShowingGroupChatDropdown(false);
      this.$emit('openModal', 'leave-chat');
    }
  },
  components: { ChatMessageRow, GroupChatDropdown },
  emits: ['openModal', 'onChatCreated']
}
</script>

<style scoped>
.panel {
  flex: 1;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  gap: 0;
}

.panel-header {
  padding: 15px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  align-items: center;
  padding-top: calc(15px + env(safe-area-inset-top));
}

.chat-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.chat-icon-holder {
  position: relative;
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon img {
  display: block;
  width: 20px;
  height: 20px;
}

.chat-name {
  font-weight: bold;
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: none;
}

#messages {
  width: 100%;
  padding: 15px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.panel-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  gap: 10px;
  padding-bottom: calc(15px + env(safe-area-inset-bottom));
}

.panel-footer .button {
  flex-shrink: 0;
  height: 43px;
}

.panel-footer textarea {
  resize: none;
  overflow: hidden;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loading-status span {
  text-align: center;
  padding: 10px;
  background: linear-gradient(to bottom right, #e4ffb9, #ccf4c9);
  color: #2b6332;
  border-radius: 10px;
}

.loading-icon {
  display: block;
  height: 100%;
}

.button img {
  height: 21px;
  display: block;
}

.send-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* Adjust spacing as needed */
}

#send {
  position: relative;
}

#send img {
  height: 22px;
  display: block;
  filter: invert(1);
}

#send .send-popup {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 10px;
}

#send .send-popup span {
  display: block;
  text-wrap: nowrap;
  background: var(--color-primary);
  color: #FFF;
  border-radius: 5px;
  padding: 15px;
}

.mark-resolved {
  position: relative;
  padding-right: 30px;
}

.unresolved-icon {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #6d3cff;
  border-radius: 50%;
  z-index: 100;
}

.user-icons {
  position: relative;
  width: 40px;
  height: 40px;
}

.user-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #EEE;
  outline: 1px solid #FFF;
  cursor: pointer;
}

.back-icon {
  display: none;
  height: 24px;
  margin-right: 15px;
}

@media screen and (max-width: 1000px) {
  .chat-label {
    display: none;
  }

  .back-icon {
    display: block;
  }

  .panel {
    border-radius: 0px;
  }
}
</style>