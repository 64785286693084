<template>
  <div class="form-section" ref="section">
    <!-- Edit View -->
    <div class="form-section-editor" v-if="isEditing">
      <input class="section-title" type="text" v-model="title" placeholder="Enter section title...">
      <textarea ref="text" @input="resizeTextArea" placeholder="Type text here..." v-model="textContent"></textarea>
    </div>
    <!-- Fill View -->
    <div class="form-section-content" v-else>
      <h2 class="section-title" v-if="section.title">{{ section.title }}</h2>
      <div class="text-content" ref="textContent"></div>
    </div>
    <!-- Overlay for edit view -->
    <transition mode="out-in" name="modal-fade">
      <div class="form-section-editor-overlay" v-if="isEditing"></div>
    </transition>
  </div>
</template>

<script>
import markdownit from 'markdown-it'

const md = markdownit({ linkify: true });

export default {
  name: 'TextSection',
  props: {
    section: Object,
    isEditing: Boolean
  },
  watch: {
    isEditing: {
      handler: function (isNowEditing) {
        if (isNowEditing) {
          this.$nextTick(() => {
            this.resizeTextArea();
          })
        } else {
          this.onUpdateSection();
          this.$nextTick(() => {
            this.loadMarkdown();
          })
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      textContent: this.section.textContent,
      title: this.section.title,
    }
  },
  methods: {
    resizeTextArea() {
      const textAreaRef = this.$refs.text;
      if (textAreaRef) {
        textAreaRef.style.height = '0px';
        this.$nextTick(() => {
          textAreaRef.style.height = `${Math.max(30, textAreaRef.scrollHeight)}px`
        })
      }
    },
    loadMarkdown() {
      const textRef = this.$refs.textContent;
      if (!textRef) return;
      if (this.textContent) {
        textRef.innerHTML = md.render(this.section.textContent);
      }
    },
    onUpdateSection() {
      const newSection = structuredClone(this.section);
      newSection.textContent = this.textContent;
      newSection.title = this.title;
      console.info('Updating section', newSection);
      this.$emit('onUpdateSection', newSection);
    }
  },
  emits: ['onUpdateSection']
}
</script>

<style scoped>
.form-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

textarea {
  background: transparent;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
}

textarea::placeholder {
  opacity: 0.5;
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

input::placeholder {
  opacity: 0.5;
}
</style>