<template>
  <div class="panel" :data-collapsed="hasChatSelected()">
    <div class="sidebar-header">
      <h2>My Groups</h2>
      <button class="button small primary" @click="openCreateChatModal">New Chat</button>
    </div>
    <div class="sidebar-body">
      <span class="empty" v-if="!loading && chats.length == 0">Internal group chats will appear here.</span>
      <div class="chat-list-items" @scroll="onScroll" ref="list">
        <router-link v-for="chat of chats" :key="chat.id" :to="`/messaging/groupchats/${chat.id}`">
          <GroupChatListItem :chat="chat" />
        </router-link>
        <div class="loading-status" v-if="!doneLoadingChats">
          <img src="/loading.svg" class="loading-icon" v-if="loading">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listGroupChats, fetchGroupChatInfo } from '@/api'
import * as socket from '../../socket.js'
import GroupChatListItem from './GroupChatListItem.vue';

const FAKE_LATENCY = 500;

export default {
  name: "OfficeGroupChatsPanel",
  props: {
    chatInfo: Object
  },
  data() {
    return {
      loading: false,
      chats: [],
      doneLoadingChats: false,
      isMinimized: false
    }
  },
  mounted() {
    this.loadLatestChats();
    socket.addListener('chat-update-event', this.handleUpdateEvent);
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  watch: {
    $route: {
      handler: function (to) {
        const chatId = to.params.id;
        if (chatId) {
          const chatInList = this.chats.find(chat => chat.id == chatId);
          if (chatInList) {
            chatInList.isResolved = true;
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async loadLatestChats() {
      try {
        this.loading = true;
        const { chats, isLast } = await listGroupChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
      }
    },
    async loadNextChats() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await listGroupChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        for (let chat of chats) {
          this.chats.push(chat);
        }

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.loading = false;
      }
    },
    async handleUpdateEvent(data) {
      const { chatId } = data;

      try {
        
        let chat;
        try {
          // Fetch chat being updated
          chat = await fetchGroupChatInfo(chatId);
        } catch (err) {
          // Failed to fetch chat, assume you've left. Remove it
          this.chats = this.chats.filter(ct => {
            return ct.id != chatId;
          })
          return;
        }

        // Remove chat from chats (if it exists), push new version
        this.chats = this.chats.filter(ct => ct.id != chat.id);
        this.chats.push(chat);

        // Sort chats by date last updated
        this.chats.sort((a, b) => {
          return new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated);
        })

      } catch (err) {
        console.error('Failed to fetch chat info for updated chat', err);
      }
    },
    hasChatSelected() {
      const params = this.$route.params;
      if (params && params.id) {
        return true;
      }

      return false;
    },
    openCreateChatModal() {
      this.$emit("openModal", "create-group-chat");
    },
    onScroll(event) {
      if (this.doneLoadingChats) {
        return; // Previous fetch returned 0 chats
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.loadNextChats();
      }
    }
  },
  components: { GroupChatListItem },
  emits: ["openModal"],
};
</script>

<style scoped>
.panel {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform ease-in-out 0.25s, opacity ease-in-out 0.25s;
}

.sidebar-header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border-med);
  padding-bottom: 25px;
}

#create-chat {
  height: 24px;
  display: block;
  cursor: pointer;
}

.sidebar-body {
  height: 1px;
  flex: 1;
}

.chat-list-items {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

a {
  text-decoration: none;
  color: unset;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}

.loading-done {
  color: #CCC;
}

.icon-sm {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
  opacity: 0.5;
}

.icon-sm:hover {
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  .panel {
    left: 10px;
    top: calc(80px + env(safe-area-inset-top));
    position: absolute;
    z-index: 100;
    height: calc(100% - 90px - env(safe-area-inset-top));
    width: calc(100% - 20px);
  }

  .panel[data-collapsed="true"] {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.empty {
  color: var(--color-txt-med);
}
</style>