<template>
  <div id="wrapper">
    <div id="full">
      <transition name="modal-appear" mode="out-in">
        <div class="modal-card" style="max-width: 500px" v-if="firstPage">
          <div class="modal-card-header">
            <h1 class="title">You have been invited</h1>
            <p class="subtitle">Welcome! You have been invited to join your team's DashQuill account. You will need to setup a login.</p>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="continueToPassword">Continue</button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
        <div class="modal-card" style="max-width: 500px" v-else>
          <div class="modal-card-header">
            <h1 class="title">Create a password</h1>
            <p class="subtitle">Please <u>make sure to use a secure password</u> that cannot be easily guessed.</p>
          </div>
          <div class="modal-card-body">
            <form class="form" action="" @submit.prevent="attemptCreateUser">
              <div class="form-group">
                <label for="email" class="form-label">Email Address</label>
                <input id="email" type="text" class="form-control" :value="invitation.email" disabled>
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" v-model="password">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Repeat Password</label>
                <input type="password" class="form-control" v-model="repassword">
              </div>
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="modal-card-footer">
            <button class="button primary" @click="attemptCreateUser">Join Team</button>
            <span class="error" v-if="error">{{ error }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {inviteInfo, createUser} from '@/api'

export default {
  name: 'AcceptInviteView',
  data() {
    return {
      firstPage: true,
      invitation: null,
      password: "",
      repassword: "",
      error: "",
      loading: false
    }
  },
  mounted() {
    this.fetchInviteInfo()
  },
  methods: {
    async fetchInviteInfo() {
      try {
        const privateURL = this.$route.params.url;
        this.invitation = await inviteInfo(privateURL);
      } catch (err) {
        console.error(err);
      }
    },
    async attemptCreateUser() {
      if (this.password != this.repassword) {
        this.error = "Passwords do not match";
        return;
      }

      try {
        this.error = "";
        this.loading = true;

        const privateURL = this.$route.params.url;
        await createUser(privateURL, this.password);
        this.$router.push('/apps');
      } catch (err) {
        this.error = err.message;
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    continueToPassword() {
      if (!this.invitation) {
        this.error = "Invitation does not exist, or has expired.";
        return;
      }

      this.firstPage = false;
    }
  },
}
</script>

<style scoped>
#wrapper {
  background-image: radial-gradient(30% 60%, #ffffff, #e1e6ea);
}

#full {
  background: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e1e6ea' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
</style>