import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AppsView from '../views/AppsView.vue'
import AccountView from '../views/AccountView.vue'
import PatientsView from '../views/PatientsView.vue'
import AcceptInviteView from '../views/AcceptInviteView.vue'
import AdminView from '../views/AdminView.vue'
import RegisterView from '../views/RegisterView.vue'
import OfficePatientChatView from '@/views/OfficePatientChatView.vue'
import OfficePatientChatViewEmbed from '@/views/OfficePatientChatViewEmbed.vue'
import PatientChatView from '@/views/PatientChatView.vue'
import OfficeGroupChatView from '@/views/OfficeGroupChatView.vue'
import OfficeMeetingsView from '@/views/OfficeMeetingsView.vue'
import OfficeUnverifiedChatView from '@/views/OfficeUnverifiedChatView.vue'
import JoinMeetingView from '@/views/JoinMeetingView.vue'
import OfficeFormsView from '../views/forms/OfficeFormsView.vue'
import PatientFormsView from '../views/forms/PatientFormsView.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView
  },
  {
    path: '/apps',
    name: 'Apps',
    component: AppsView
  },
  {
    path: '/settings/:page?',
    name: 'Settings',
    component: AccountView
  },
  {
    path: '/patients',
    name: 'Patients',
    component: PatientsView
  },
  {
    path: '/invite/:url',
    name: 'Accept Invite',
    component: AcceptInviteView
  },
  {
    name: 'Office Patient Chat',
    path: '/messaging/patients/:id?',
    component: OfficePatientChatView
  },
  {
    name: 'Office Group Chat',
    path: '/messaging/groupchats/:id?',
    component: OfficeGroupChatView
  },
  {
    name: 'Unverified Chat',
    path: '/messaging/unverified/:id?',
    component: OfficeUnverifiedChatView
  },
  { // TODO: Put under /messaging/ prefix or leave it in root 
    name: 'Office Patient Chat Embed',
    path: '/embed/:emrId',
    component: OfficePatientChatViewEmbed
  },
  {
    name: 'Patient Chat',
    path: '/chat/:url',
    component: PatientChatView
  },
  {
    name: 'Join Meeting',
    path: '/:name/meeting',
    component: JoinMeetingView
  },
  {
    name: 'Meetings',
    path: '/meetings',
    component: OfficeMeetingsView
  },
  {
    name: 'Forms',
    path: '/forms/:id?',
    component: OfficeFormsView
  },
  {
    name: 'Fill Forms',
    path: '/:name/forms',
    component: PatientFormsView
  },
]

const router = createRouter({
  routes: routes,
  history: createWebHistory()
})

export default router;