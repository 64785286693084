<template>
  <nav>
    <!-- Navigation Logo -->
    <router-link class="nav-logo" to="/apps">
      <img src="@/assets/logo-lg.png" class="logo-image logo-image-lg" alt="Small DashQuill Logo">
      <img src="@/assets/logo-sm.png" class="logo-image logo-image-sm" alt="Large DashQuill Logo">
    </router-link>
    <!-- Service Links -->
    <div class="nav-links">
      <div class="nav-links-list">
        <router-link class="nav-link" v-for="link of links" :key="link.path" :to="link.path"
          :data-active="$route.path.startsWith(link.path)">
          <span class="nav-link-label">{{ link.label }}</span>
          <div class="notif-marker" v-if="link.hasNotif"></div>
        </router-link>
      </div>
      <div class="fade-right"></div>
    </div>
    <!-- User Information -->
    <div class="nav-info">
      <!-- <div class="notif-info">
        <img src="/icons/bell.png" class="notif-icon" alt="Notification Bell">
      </div> -->
      <div class="user-info" ref="userInfo" @click="isNavPopupOpen = true">
        <img :src="getProfilePicture()" class="user-icon" alt="User Icons">
        <span class="user-name">{{ store.userInfo.name }}</span>
        <transition name="modal-appear" mode="out-in">
          <NavigationBarPopup v-if="isNavPopupOpen" />
        </transition>
      </div>
    </div>
  </nav>
</template>

<script>
import { store, fetchUserInfo } from '@/store'
import NavigationBarPopup from './NavigationBarPopup.vue'

export default {
  name: 'NavigationBar',
  props: {
    links: Array
  },
  data() {
    return {
      store,
      isNavPopupOpen: false
    }
  },
  created() {
    fetchUserInfo()
      .then(() => {
        console.info('User info - OK')
      })
      .catch(err => {
        console.error('Failed to fetch user info', err);
      })
  },
  mounted() {
    window.addEventListener('click', this.onClickPage);
    window.addEventListener('touchstart', this.onClickPage);
  },
  unmounted() {
    window.removeEventListener('click', this.onClickPage);
    window.removeEventListener('touchstart', this.onClickPage);
  },
  methods: {
    getProfilePicture() {
      if (!this.store.userInfo) {
        return '/icons/patient.png'
      }

      if (!this.store.userInfo.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${this.store.userInfo.profilePictureKey}`;
    },
    onClickPage(e) {
      const userInfoRef = this.$refs.userInfo;
      if (userInfoRef && !userInfoRef.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();
        this.isNavPopupOpen = false;
      }
    }
  },
  components: { NavigationBarPopup }
}
</script>

<style scoped>
nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 8px #00000022;
  background: var(--color-fill-light);
  padding: 15px;
  padding-top: calc(15px + env(safe-area-inset-top));
  min-height: 70px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo-image {
  height: 30px;
  display: block;
}

.logo-image-sm {
  display: none;
}

.logo-image-lg {
  display: block;
}

.nav-links {
  position: relative;
  width: 1px;
  flex: 1;
}

.nav-links-list {
  display: flex;
  align-items: center;
  gap: 15px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav-links-list::-webkit-scrollbar {
  display: none;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-size: 16px;
  color: #000;
  padding: 8px;
  border-radius: 5px;
  text-wrap: nowrap;
  margin: 1px;
}

.nav-link[data-active="true"] {
  background: var(--color-fill-med);
}

.notif-marker {
  flex-shrink: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--color-primary);
  border: 1px solid #4a00c2;
}

.notif-icon {
  height: 24px;
  display: block;
}

.nav-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.user-icon {
  height: 30px;
  display: block;
  border-radius: 50%;
}

.fade-right {
  pointer-events: none;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  background: linear-gradient(to right, transparent, #FFF);
}

@media screen and (max-width: 1000px) {
  .logo-image-sm {
    display: block;
  }

  .logo-image-lg {
    display: none;
  }

  .user-name {
    display: none;
  }

  .fade-right {
    display: block;
  }

  .nav-links-list {
    padding-right: 25px;
  }
}
</style>