<template>
  <div class="panel">
    <div class="sidebar-header">
      <h2>My Forms</h2>
      <button class="button small primary" @click="$emit('openModal', 'create-form')">Create Form</button>
    </div>
    <div class="sidebar-body">
      <span class="empty" v-if="forms.length == 0">Patient forms will appear here.</span>
      <div class="form-list-items">
        <router-link v-for="form of forms" :key="form.id" :to="`/forms/${form.id}`">
          <FormListItem :form="form" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FormListItem from './FormListItem.vue';
import * as formsApi from '../../api/forms.js'

export default {
  name: 'FormsListSidebar',
  data() {
    return {
      forms: [],
      loading: false,
    }
  },
  created() {
    this.attemptFetchForms();
  },
  methods: {
    async attemptFetchForms() {
      try {
        const forms = await formsApi.listForms();
        this.forms = forms;
        console.info('Fetched forms from backend');
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: { FormListItem },
  emits: ['openModal']
}
</script>

<style scoped>
.panel {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform ease-in-out 0.25s, opacity ease-in-out 0.25s;
}

.sidebar-header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border-med);
  padding-bottom: 25px;
}

.sidebar-body {
  height: 1px;
  flex: 1;
}

.form-list-items {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

a {
  text-decoration: none;
  color: unset;
}
</style>