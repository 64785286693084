<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">DashQuill Meetings</h1>
      <p class="subtitle">Secure telemedicine/meeting platform.</p>
    </div>
    <div class="modal-card-body">
      <img src="/placeholder.png" alt="Placeholder" class="placeholder">
    </div>
    <div class="modal-card-footer">
      <button class="button primary" v-if="hasProduct" @click="$emit('launchApp')">Launch</button>
      <button class="button primary" v-else disabled>Contact DashQuill</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetingsAppModal",
  props: {
    hasProduct: Boolean
  },
  emits: ['launchApp']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.placeholder {
  width: 100%;
}
</style>