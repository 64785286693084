import { reactive } from "vue";
import { io } from "socket.io-client";
import { userInfo } from '@/api';

export const state = reactive({
  connected: false,
  roomsJoined: new Set(),
  listeners: new Map()
});

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.VUE_APP_API;
console.log('url', URL);
let socket = null;

export function isConnectedToSocket() {
  return state.connected;
}

export async function connectToSocket() {

  if (state.connected) {
    throw Error('Socket is already connected');
  }

  return new Promise((resolve, reject) => {
    // Create socket
    socket = io(URL, {
      withCredentials: true
    });

    socket.on('connect', () => {
      state.connected = true;
      resolve();
    });

    socket.on('connect_error', () => {
      state.connected = false;
      socket = null;
      reject();
    })

    socket.on('disconnect', (reason) => {
      state.connected = false;
      state.roomsJoined.clear();
      socket = null;
      console.info('Socket disconnected for reason: ' + reason);
    })

    socket.onAny((event, data) => {
      console.log('Received Event', event, data);
      if (!state.listeners.has(event)) {
        console.log("Returning");
        return;
      }

      const callbacks = state.listeners.get(event);
      for (let cb of callbacks) {
        try {
          console.log('Found the callback', data);
          cb(data);
        } catch (err) {
          console.warn('Failed to invoke callback', err);
        }
      }
    })
  })
}

export function disconnectFromSocket() {
  if (!state.connected) {
    throw new Error('Socket is not connected');
  }

  socket.disconnect();
}

export async function joinAccountRoom() {
  if (!state.connected) {
    throw new Error('Socket is not connected');
  }

  // Fetch account info
  const user = await userInfo();
  const roomId = user.accountId;

  // If they're not in room yet, join
  if (!state.roomsJoined.has(roomId) && socket) {
    socket.emit('joinRoom', roomId);
    state.roomsJoined.add(roomId);
  }
}

export async function joinPatientRoom(roomId) {
  if (!state.connected) {
    throw new Error('Socket is not connected');
  }

  // If they're not in room yet, join
  if (!state.roomsJoined.has(roomId) && socket) {
    socket.emit('joinRoom', roomId);
    state.roomsJoined.add(roomId);
  }
}

// Register this callback so it gets called when a message arrives
export function addListener(eventName, callback) {
  if (!state.listeners.has(eventName)) {
    state.listeners.set(eventName, []);
  }

  state.listeners.get(eventName).push(callback);
  console.log(`Listener added for event ${eventName}`);
}

// Unregister the callback
export function removeListener(eventName, callback) {
  const callbacks = state.listeners.get(eventName);
  state.listeners.set(eventName, callbacks.filter(cb => cb != callback));
  console.log(`Listener removed for event ${eventName}`);
}