<template>
  <div class="groupchat-dropdown">
    <img src="/icons/close.png" alt="Close Icon" class="close-modal-icon" @click.prevent.stop="$emit('closePopup')">
    <h2>Chat Members</h2>
    <div class="user-item" v-for="user of users" :key="user.id">
      <img :src="getUserProfilePicture(user)" :alt="user.name" class="user-icon">
      <div class="user-description">
        <span class="user-name">{{ user.name }}</span>
        <span class="user-role">{{ user.practiceRole }}</span>
      </div>
    </div>
    <div class="row action-row">
      <button @click.stop.prevent="$emit('onAddMember')" class="button primary">Add Members</button>
      <a href="#" @click.stop.prevent="$emit('onLeaveChat')" class="leave-chat error">Leave Group Chat</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupChatDropdown',
  props: {
    users: Array,
  },
  methods: {
    getUserProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
  },
  emits: ['onAddMember', 'onLeaveChat', 'closePopup']
}
</script>

<style scoped>
.groupchat-dropdown {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  min-width: 350px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;
}

@media screen and (max-width: 1000px) {
  .groupchat-dropdown {
    position: fixed;
    top: calc(10px + env(safe-area-inset-top));
    left: 10px;
    width: calc(100% - 20px);
  }
}

.user-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #EEE;
  outline: 1px solid #FFF;
  cursor: pointer;
}

.user-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.user-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-name {
  font-weight: bold;
}

.action-row {
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.leave-chat {
  text-align: center;
  display: block;
  text-decoration: none;
  margin-top: 15px;
}
</style>