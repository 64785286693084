<template>
  <div class="container" :style="getStyle()" :data-checked="isChecked">
    <div class="checkbox-icon"></div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    size: String,
    thickness: String,
    borderColor: String,
    unfillColor: String,
    fillColor: String,
    lineColor: String,
    isChecked: Boolean
  },
  methods: {
    getStyle() {
      return {
        '--size': this.size || '20px',
        '--thickness': this.thickness || '20px',
        '--borderColor': this.borderColor || 'var(--color-border-dark)',
        '--unfillColor': this.unfillColor || '#FFF',
        '--fillColor': this.fillColor || 'var(--color-primary)',
        '--lineColor': this.lineColor || '#FFF'
      }
    }
  },
}
</script>

<style scoped>
.container {
  position: relative;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border: 1px solid var(--borderColor);
  background: var(--unfillColor);
  border-radius: 3px;
  flex-shrink: 0;
  cursor: pointer;
}

.container[data-checked="true"] {
  background: var(--fillColor);
}

.container[data-checked="true"] .checkbox-icon {
  position: absolute;
  left: 30%;
  top: 10%;
  width: 35%;
  height: 70%;
  transform-origin: center;
  border-right: var(--thickness) solid var(--lineColor);
  border-bottom: var(--thickness) solid var(--lineColor);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}
</style>