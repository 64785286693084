<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Add Members To Chat</h1>
      <p class="subtitle">Select team members that you want to add to this chat. <u>Warning: They will be able to see all previous messages.</u></p>
    </div>
    <div class="modal-card-body">
      <div class="user-list-item" v-for="user of users" :key="user.id" @click="onToggleUser(user.id)">
        <img :src="getProfilePicture(user)" alt="User Picture" class="user-picture"
          @click.stop.prevent="$emit('onEditPicture', user)">
        <span class="user-name">{{ user.name }}</span>
        <span class="user-role">{{ user.practiceRole }}</span>
        <div class="spacer" style="flex: 1"></div>
        <CheckBox size="25px" thickness="3px" :isChecked="isUserSelected(user.id)" />
      </div>
      <span class="empty-list" v-if="users.length == 0">All account users are in this group</span>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" :disabled="selected.size == 0" @click.prevent="attemptAddMembers">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Add {{ selected.size }} Members</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { listUsers, groupChatAddMembers } from '@/api';
import CheckBox from '../../components/CheckBox.vue';

export default {
  name: "AddGroupChatMemberModal",
  props: {
    chatInfo: Object
  },
  data() {
    return {
      name: '',
      users: [],
      selected: new Set(),
      loading: false
    }
  },
  mounted() {
    this.attemptFetchUsers();
  },
  methods: {
    onToggleUser(userId) {
      if (this.selected.has(userId)) {
        this.selected.delete(userId);
      } else {
        this.selected.add(userId);
      }
    },
    isUserSelected(userId) {
      return this.selected.has(userId)
    },
    openNextModal() {
      const selectedUsers = this.newUsers.filter(user => this.isUserSelected(user.id));
      this.$emit('openModal', 'name-group-chat', { users: selectedUsers })
    },
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    async attemptFetchUsers() {
      try {
        const users = await listUsers();
        const usersInChat = this.chatInfo.users;
        this.users = users.filter(user => {
          return !usersInChat.some(usr => usr.id == user.id);
        });
      } catch (err) {
        console.error('Error', err);
      }
    },
    async attemptAddMembers() {
      try {
        this.loading = true;
        const users = Array.from(this.selected);
        await groupChatAddMembers(this.chatInfo.id, users);
        this.$emit('closeModal');
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  components: { CheckBox },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
}

.user-list-item:hover {
  background: var(--color-fill-med);
}

.user-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: var(--color-txt-med);
}

.empty-list {
  color: var(--color-txt-med);
  background: var(--color-fill-med);
  padding: 10px;
  border-radius: 5px;
}
</style>